@import "assets/scss/vars.scss";

// .ForgetPassword {
// }
.Success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Success :global(.TitleFont:first-child) {
  margin-bottom: 50px;
}
.Success :global(.TitleFont:last-of-type) {
  margin-bottom: 10px !important;
}
.Success :global(.DescFont) {
  opacity: 0.5;
}
