@import "assets/scss/vars.scss";
/*-- DESKTOP SIDE-BAR --*/
.SideBarHolder {
  position: sticky;
  top: 100px;
  z-index: 9;
}

.SideBar {
  padding: 30px;
  border-radius: 30px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  background-color: #ffffff;
  z-index: 3;
}

@media only screen and (max-width: 768px) {
  .SideBarHolder {
    position: relative;
    top: 0;
    margin-bottom: 30px;
  }

  :global(.SideTitleResp) {
    padding: 13px 12px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(112, 112, 112, 0.2);
    display: flex;
    align-items: center;
    position: relative;
    color: #e22523;
    transition: all 0.3s;
  }

  :global(.SideTitleResp).active {
    border-radius: 30px 30px 0 0;
    border-bottom: 0;
  }

  :global(.SideTitleResp) .DropdownIcon {
    color: #00468b;
    transition: all 0.3s;
  }

  :global(.SideTitleResp).active .DropdownIcon {
    transform: rotate(180deg);
  }

  :global(.SideTitleResp) .side-link {
    color: #e22523;
  }

  :global(.SideTitleResp) .DropdownIcon {
    position: absolute;
    #{$ltr} & {
      right: 12px;
    }
    #{$rtl} & {
      left: 12px;
    }
    font-size: 8px;
  }

  .SideBar {
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    transform: translateY(100px);
  }

  .SideBar.Show {
    transform: none;
    opacity: 1;
    visibility: visible;
    border-radius: 0 0 30px 30px;
  }
}
