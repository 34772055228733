@import "assets/scss/vars.scss";

.Corporate {
}

.DatePicker {
  position: relative;
}

.DatePicker i {
  font-size: 21px;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  color: #002b69;
  pointer-events: none;
}

.disabledSignUpBtn {
  cursor: no-drop !important;
}
