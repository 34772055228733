@import "assets/scss/vars.scss";

.ProfileBankNews {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  .ProfileBankNews {
    padding-top: 30px;
    padding-bottom: 30px;
    // overflow: hidden;
  }
}
