@import "assets/scss/vars.scss";

.PersonalTable {
  overflow-x: hidden;
}

.TitleTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Tabs {
  display: flex;
  color: #000;
}

.Tabs span {
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
  cursor: pointer;
}

.Tabs span:last-child {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$rtl} & {
    margin-left: 0;
  }
}

:global(.table) {
  width: 825px !important;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.Table thead th {
  border-bottom: 0;
  border-top: 0;
  vertical-align: middle;
  padding: 0;
  background-color: #fcfcfc;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-weight: normal;
  padding: 20px;
  max-width: 825px;
}

.Table thead th:first-child {
  #{$ltr} & {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #{$rtl} & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  white-space: nowrap;
}

.Table thead th:last-child {
  #{$ltr} & {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  #{$rtl} & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.Table thead th div {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  cursor: pointer;
}

.Table thead th div i {
  font-size: 8px;
  color: #00158b;
  font-weight: bold;
  #{$ltr} & {
    margin-left: 10px;
  }
  #{$rtl} & {
    margin-right: 10px;
  }
  transition: all 0.3s;
}

.Table thead th div.Active i {
  transform: rotate(180deg);
}

.Table tbody td {
  border-top: 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 20px;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-weight: normal;
  vertical-align: middle;
}
.Table tbody td:last-child {
  white-space: nowrap;
  #{$ltr} & {
    padding-right: 0;
  }
  #{$rtl} & {
    padding-left: 0;
  }
}

.Table tbody td div.Status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
}

.Table tbody td div.ArrowLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Table tbody td div.ArrowLink a {
  #{$ltr} & {
    transform: rotate(270deg);
  }
  #{$rtl} & {
    transform: rotate(90deg);
  }
}

.Table tbody td div.ArrowLink a i {
  font-size: 8px;
  color: #00158b;
  font-weight: bold;
}

.Table tbody td.NoAvailableData {
  text-align: center;
  border-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  font-family: "Lato";
  text-transform: uppercase;
}

.Dot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  #{$ltr} & {
    margin-right: 5px;
  }
  #{$rtl} & {
    margin-left: 5px;
  }
}

:global(.new) {
  color: #ffbe0d;
}

:global(.new) .Dot {
  background-color: #ffbe0d;
}

:global(.approved) {
  color: #1ce1ac;
}

:global(.approved) .Dot {
  background-color: #1ce1ac;
}

:global(.inprogress) {
  color: #1457e9;
}

:global(.inprogress) .Dot {
  background-color: #1457e9;
}

:global(.rejected) {
  color: #e22523;
}

:global(.rejected) .Dot {
  background-color: #e22523;
}

:global(.withdrawal) {
  color: #ff00ff;
}

:global(.withdrawal) .Dot {
  background-color: #ff00ff;
}

/*** Pagination ***/
:global(.pagination) {
  justify-content: center;
  margin-top: 20px;
}

:global(.pagination .page-item) {
  cursor: pointer;
}

:global(.pagination .page-item.disabled) {
  cursor: no-drop;
}

:global(.pagination .page-link) {
  cursor: pointer;
  color: #000;
  font-size: 12px;
  line-height: 17px;
  font-family: "Lato";
}

:global(.page-item:first-child .page-link) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  #{$ltr} & {
    border-right: 0;
  }
  #{$rtl} & {
    border-left: 0;
  }
}

:global(.page-item:last-child .page-link) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global(.page-item.active .page-link) {
  background-color: #fff;
  color: #e22523;
  border-color: #dee2e6;
}

:global(.page-link:hover) {
  background-color: transparent;
  color: #e22523;
}

/*** Loader ***/
:global(.spinner-border) {
  border-color: #e22523;
  border-right-color: transparent;
  position: relative;
  #{$ltr} & {
    left: 50%;
  }
  #{$rtl} & {
    right: 50%;
  }
  width: 50px;
  height: 50px;
  margin: 50px 0;
  width: 50px;
  height: 50px;
}

.arrow_up {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .PersonalTable {
    overflow-x: auto;
  }

  .TitleTabs {
    display: block;
  }

  .TitleTabs .Title {
    margin-bottom: 30px;
  }

  .Tabs {
    white-space: nowrap;
    overflow-x: auto;
  }

  :global(.pagination) {
    margin-bottom: 20px;
  }
}
