@import "assets/scss/vars.scss";

.SignUp {
}
.Radio {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #707070;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  #{$ltr} & {
    margin-right: 10px;
  }
  #{$rtl} & {
    margin-left: 10px;
  }
}
.Radio:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: transparent;
  border-radius: 100%;
  transition: all 0.3s;
}
.Tab.active .Radio:before {
  background-color: #e22523;
}
.Tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
}
.Tabs .Tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
}
.need {
  margin-bottom: 20px;
}
.Quis {
  display: block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.2);
  border-radius: 50%;
  font-size: 42px;
  color: #e22523;
  #{$rtl} & {
    transform: scaleX(-1);
  }
}
.Quis:hover {
  background-color: #e22523;
  color: #ffffff;
}
.SignUp :global(.LogBtns) {
  margin-top: 22px;
}
@media only screen and (max-width: 768px) {
  .Tabs {
    flex-direction: column;
    align-items: flex-start;
  }
  .Tabs .Tab:first-child {
    margin-bottom: 30px;
  }
  .Quis {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$ltr} & {
      right: 0;
    }
    #{$rtl} & {
      left: 0;
    }
  }
}
