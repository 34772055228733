@import "assets/scss/vars.scss";

.ModalCard :global(.DropInner) {
  background-color: #ffffff;
}
.ModalCard :global(.dropdown-menu) {
  background-color: transparent;
}
:global(.NotificationModal .modal-content) {
  position: relative;
  padding: 70px 95px 55px;
  width: 920px;
  margin: 100px auto 100px;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  z-index: 2;
  // opacity: 0;
  // transform: scale(0.7);
  // transition: all 0.3s ease-in;
  // transition-delay: 0.1s;
  background-color: white;
}
:global(.NotificationModal.FormPadding .modal-content) {
  padding-bottom: 40px;
}
:global(.NotificationModal.show .modal-content) {
  // transform: scale(1);
  // opacity: 1;
  // transition: all 0.3s ease-out;
}
:global(.ModalOpen) {
  animation: openModal 0.5s ease-out forwards;
}

:global(.ModalClosed) {
  animation: closeModal 0.3s ease-out forwards;
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 0.8;
    transform: translateY(20%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
.ModalCard .CloseIcon {
  position: absolute;
  top: 0;
  right: 95px;
  transform: translateY(-50%);
  background-color: #e22523;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none;
}

.ModalCard .Close {
  color: white;
  font-size: 50px;
}

@media only screen and (max-width: 768px) {
  :global(.NotificationModal .modal-content) {
    max-width: 90%;
    padding: 60px 32px 30px 32px;
  }

  .ModalCard .CloseIcon {
    right: 30px;
  }

  :global(.NotificationModal .modal-content .SignOutTitle) {
    margin-bottom: 30px;
  }
  :global(.NotificationModal .modal-content) {
    margin: 50px auto 50px;
  }
}
