@import "assets/scss/vars.scss";

.LatestNewsContainer {
	background-color: #fcfcfc;
	overflow: hidden;
	position: relative;
}
.LatestNewsContainer :global(.container) {
	position: relative;
}
.LatestNewsContainer :global([class*="col-"]),
.LatestNewsContainer :global(.owl-carousel) {
	position: static;
}
:global(.owl-carousel.owl-drag .owl-item) {
	transition: 0.3s ease-out;
}
.LatestNewsContainer :global(.owl-theme .owl-nav) {
	position: absolute;
	#{$ltr} & {
		right: 15px;
	}
	#{$rtl} & {
		left: 15px;
	}
	top: 5px;
	font-size: 31px;
	margin: 0;
	height: 20px;
	line-height: 20px;
}
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-prev),
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-next) {
	width: auto;
	padding: 0 !important;
	margin: 0;
	height: 20px;
	background-color: transparent;
	color: #000000;
	transition: 0.3s;
}
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-prev:hover),
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-next:hover) {
	color: #00158b;
}
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-prev.disabled),
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-next.disabled) {
	opacity: 0;
	visibility: hidden;
}
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-prev i),
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-next i) {
	line-height: 20px;
	display: block;
}
.LatestNewsContainer :global(.owl-theme .owl-nav button.owl-next) {
	margin-left: 30px;
}
.LatestNewsContainer :global(.owl-stage-outer) {
	overflow: visible;
}
.LatestNewsContainer :global(.owl-item) {
	opacity: 0.2;
}
.LatestNewsContainer :global(.owl-item.active) {
	opacity: 1;
}

.Wrapper {
	padding-bottom: 25px;
}
.LatestNewsOuter {
	position: relative;
	transition: all 0.3s;
	border-radius: 30px;
}

.LatestNews {
	padding: 40px;
	padding-bottom: 55px;
	padding-top: 35px;
	border-radius: 30px;
	background-color: white;
	overflow: hidden;
	transition: all 0.3s;
	position: relative;
}

.LatestNewsOuter:hover {
	box-shadow: 0px 0px 65px 10px rgba(0, 14, 92, 0.15);
}

.LatestNews .LatestHead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 17px;
}

.LatestNews .LatestHead .Date {
	color: rgba(0, 0, 0, 0.5);
}

.LatestNews .Tit {
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.LatestNews .Tit .LatestNewsImage {
	width: 101px !important;
	height: 101px;
	flex-shrink: 0;
	#{$ltr} & {
		margin-right: 20px;
	}
	#{$rtl} & {
		margin-left: 20px;
	}
}

.LatestNews .Tit :global(.SubTitle) {
	font-size: 16px;
}

.LatestNews .Tit .LatestNewsImage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.LatestNews .Tit p {
	font-family: latoBl;
	height: 87px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.LatestNews .Desc {
	height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.LatestNewsOuter .Button {
	position: absolute;
	#{$ltr} & {
		left: 95px;
	}
	#{$rtl} & {
		right: 95px;
	}
	bottom: 0;
	transform: translateY(50%);
	background-color: #ffffff;
	color: #e22523;
}

.LatestNewsOuter .Button:hover {
	background-color: #e22523;
	color: white;
}

.DateTime {
	font-size: 14px;
	line-height: 26px;
	font-family: "Lato";
	color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
	.LatestNewsOuter .Button {
		#{$ltr} & {
			left: 50% !important;
			transform: translate(-50%, 50%);
		}
		#{$rtl} & {
			right: 50% !important;
			transform: translate(50%, 50%);
		}
	}

	.LatestNews .LatestHead .Date {
		flex-shrink: 0;
	}

	.LatestNews .Tit {
		display: block;
		margin-bottom: 10px;
	}

	.LatestNews .Tit img {
		margin-bottom: 20px;
		margin-right: 0;
	}

	.LatestNewsContainer :global(.owl-theme .owl-nav) {
		position: static;
		padding-top: 30px;
	}

	.LatestNews .LatestHead {
		#{$rtl} & {
			direction: rtl;
			justify-content: flex-start;
		}
	}

	.LatestNews :global(.shareIco) {
		direction: ltr;
	}

	.LatestNews .Tit .LatestNewsImage {
		margin-bottom: 20px;
	}
}
