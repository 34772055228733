@import "assets/scss/vars.scss";

.Tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Download {
  color: #e22523 !important;
  cursor: pointer;
  display: flex;
}
.Download .DownloadIcon {
  #{$ltr} & {
    margin-right: 11px;
  }
  #{$rtl} & {
    margin-left: 11px;
  }
  transform: rotate(90deg);
}
.Download a {
  color: #e22523;
}
.Download p {
  color: #e22523;
}
.CaseId {
  color: #e22523 !important;
  display: inline-block;
  margin-bottom: 50px;
  font-family: latoB;
}
.TableWrapper {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
.TableWrapper table {
  width: 100%;
}

.TableWrapper table tr {
  vertical-align: top;
}

.TableWrapper table tr td {
  padding-bottom: 30px;
}

.TableWrapper table tr td:first-child {
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  // white-space: nowrap;
  #{$ltr} & {
    padding-right: 10px;
  }
  #{$rtl} & {
    padding-left: 10px;
  }
}
.TableWrapper table tr td:last-child {
  color: rgba(0, 0, 0, 0.5) !important;
  font-family: LatoB;
  font-size: 12px;
  line-height: 15px;
  width: 30%;
  word-break: break-word;
}

.DownloadLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.DownloadLinks .Download:first-child {
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
}

.errorMsg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DescFont {
  color: #e22523 !important;
}

.backLink {
  text-transform: uppercase;
  text-decoration: underline !important;
}
