@import "assets/scss/vars.scss";

// .RequestTable {
//   overflow-x: hidden;
// }

.RequestInfo {
  margin-bottom: 10px;
}

.Info {
  margin-bottom: 20px;
  color: #000;
}

.Title {
  margin-bottom: 50px;
}

.InfoName {
  font-size: 14px;
  line-height: 17px;
  font-family: "LatoB";
  color: #000;
}

:global(.table) {
  width: 825px !important;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.Table thead th {
  border-bottom: 0;
  border-top: 0;
  vertical-align: middle;
  padding: 20px;
  background-color: #fcfcfc;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-weight: normal;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 825px;
}

.Table thead th:first-child {
  #{$ltr} & {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #{$rtl} & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  white-space: nowrap;
}

.Table thead th:last-child {
  #{$ltr} & {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  #{$rtl} & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.Table thead th div {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  cursor: pointer;
}

.Table thead th div i {
  font-size: 8px;
  color: #00158b;
  font-weight: bold;
  #{$ltr} & {
    margin-left: 10px;
  }
  #{$rtl} & {
    margin-right: 10px;
  }
  transition: all 0.3s;
}

.Table thead th div.Active i {
  transform: rotate(180deg);
}

.Table tbody td {
  border-top: 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 20px;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-weight: normal;
  vertical-align: middle;
}

.Table tbody td:first-child {
  font-size: 12px;
  line-height: 18px;
}

.Table tbody td div.Status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.Table tbody td.NoData {
  border-bottom: 0;
  text-align: center;
}

.Dot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  flex-shrink: 0;
  #{$ltr} & {
    margin-right: 5px;
  }
  #{$rtl} & {
    margin-left: 5px;
  }
}

:global(.new) {
  color: #ffbe0d;
}

:global(.new) .Dot {
  background-color: #ffbe0d;
}

:global(.approved) {
  color: #1ce1ac;
}

:global(.approved) .Dot {
  background-color: #1ce1ac;
}

:global(.inprogress) {
  color: #1457e9;
}

:global(.inprogress) .Dot {
  background-color: #1457e9;
}

:global(.rejected) {
  color: #e22523;
}

:global(.rejected) .Dot {
  background-color: #e22523;
}

:global(.withdrawal) {
  color: #ff00ff;
}

:global(.withdrawal) .Dot {
  background-color: #ff00ff;
}

:global(.initialaccepted) {
  color: #ff1493;
}

:global(.initialaccepted) .Dot {
  background-color: #ff1493;
}

:global(.returned) {
  color: #ff7900;
}

:global(.returned) .Dot {
  background-color: #ff7900;
}

.ApprovedIcon {
  background-color: transparent;
  border: 0;
  flex-shrink: 0;
  cursor: pointer;
}

.ApprovedIcon img {
  width: 100%;
  max-width: 11px;
  height: 11px;
}

.AcceptOfferContent {
  margin-top: 50px;
}

.AcceptOfferContent table tbody tr td {
  padding-bottom: 15px;
}

.AcceptOfferContent table tbody tr:last-child td {
  padding-bottom: 0;
}

.AcceptOfferContent table tbody tr td:first-child {
  #{$ltr} & {
    padding-right: 20px;
  }
  #{$rtl} & {
    padding-left: 20px;
  }
}

.AcceptBankContent {
  margin-top: 50px;
}

.AcceptBankContent .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.AcceptBankContent .BankInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
}

.AcceptBankContent .BankInfo:last-child {
  margin-right: 0;
}

.AcceptBankContent .bottom .BankInfo {
  margin-right: 0;
}

.AcceptBankContent .BankInfo .NameTitle {
  #{$ltr} & {
    margin-right: 10px;
  }
  #{$rtl} & {
    margin-left: 10px;
  }
}

// Edit and Cancel Buttons
.RequestInfoHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.RequestInfoHead .Title {
  margin-bottom: 0;
}

.RequestInfoHead .RequestInfoButtons {
  display: flex;
  align-items: start;
  justify-content: flex-start;
}

.RequestInfoHead .RequestInfoButtons :global(.Button) {
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
}

.RequestInfoHead .RequestInfoButtons :global(.Button:last-child) {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$rtl} & {
    margin-left: 0;
  }
}

.RequestTable tbody td.NoAvailableData {
  text-align: center;
  border-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  font-family: "Lato";
  text-transform: uppercase;
}

.RequestTable tbody td.Unfortunately {
  border-bottom: 0;
}

.NoBankWithdrawal h1 {
  margin-bottom: 20px;
}

.NoBankWithdrawal p {
  margin-bottom: 30px;
}

.Resubmit {
  #{$ltr} & {
    margin-left: auto;
  }
  #{$rtl} & {
    margin-right: auto;
  }
}

.ResubmittedService {
  color: #e22523 !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.disabledServices {
  cursor: no-drop !important;
  opacity: 0.5;
}

.disabledServices:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .RequestTable {
    overflow-x: auto;
  }

  .Title {
    margin-bottom: 30px;
  }

  .RequestInfoHead {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .RequestInfoHead .Title {
    margin-bottom: 30px;
  }

  .AcceptOfferContent {
    margin-top: 0;
  }

  .AcceptOfferContent table tbody tr {
    vertical-align: top;
  }

  .AcceptOfferContent table tbody tr td:first-child {
    max-width: 175px;
  }

  .AcceptBankContent {
    margin-top: 0;
  }

  .AcceptBankContent .top {
    flex-direction: column;
    align-items: flex-start;
  }

  .AcceptBankContent .BankInfo {
    #{$ltr} & {
      margin-right: 0;
    }
    #{$rtl} & {
      margin-left: 0;
    }
    align-items: flex-start;
  }

  .AcceptBankContent .BankInfo:first-child {
    margin-bottom: 20px;
  }
}
