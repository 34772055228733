@import 'assets/scss/vars.scss';

.Tit {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.Tit .Title {
  flex-shrink: 0;
  #{$ltr} & {
    margin-right: 80px;
  }
  #{$rtl} & {
    margin-left: 80px;
  }
}
.Tit .Desc {
  max-height: 33px;
  overflow: hidden;
}

.AddPadding {
  padding: 100px 0 70px;
}

@media only screen and (max-width: 768px) {
  .Tit {
    display: block;
    margin-bottom: 30px;
  }
  .Tit .Title {
    margin-bottom: 20px;
    #{$ltr} & {
      margin-right: 0;
    }
    #{$rtl} & {
      margin-left: 0;
    }
  }

  .AddPadding {
    padding: 30px 0 0;
    }
}