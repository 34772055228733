@import "assets/scss/vars.scss";

@import "assets/scss/vars.scss";

.Page500 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  text-align: center;
}
.Logo {
  width: 63px;
  margin-top: 20px;
  margin-bottom: 134px;
}
.ErrorNum {
  font-size: 261px;
  line-height: 314px;
  font-family: latoBl;
  color: #00158b;
  margin-bottom: 9px;
}

.Tit {
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .Logo {
    margin-top: 30px;
    margin-bottom: 130px;
  }
  .ErrorNum {
    font-size: 202px;
    line-height: 242px;
    margin-bottom: 0;
  }
}
