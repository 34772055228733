@import "assets/scss/vars.scss";

.RejectWithdrawal .SelectReason {
  min-width: 100%;
  max-width: fit-content;
}

.RejectWithdrawal :global(.react-select-container .react-select__control) {
  min-height: 43px;
  height: auto;
}
