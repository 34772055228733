@import "assets/scss/vars.scss";

.Page404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 90px);
}

.ErrorNum {
  font-size: 261px;
  line-height: 314px;
  font-family: latoBl;
  color: #00158b;
  margin-bottom: 9px;
}

.Tit {
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .ErrorNum {
    font-size: 202px;
    line-height: 242px;
    margin-bottom: 0;
  }
}
