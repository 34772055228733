@import "assets/scss/vars.scss";

.VisionSlider {
  position: relative;
  padding: 100px 0 0 0;
  overflow: hidden;
  :global {
    .owl-carousel.owl-drag .owl-item {
      opacity: 0.1;
    }

    .owl-carousel.owl-drag .owl-item.active + .active {
      #{$ltr} & {
        opacity: 0.1;
      }
      #{$rtl} & {
        opacity: 1;
      }
    }

    .owl-carousel.owl-drag .owl-item.active {
      #{$ltr} & {
        opacity: 1;
      }
      #{$rtl} & {
        opacity: 0.1;
      }
    }

    .owl-carousel .owl-nav button {
      outline: none;
      border: 0;
      margin: 0;
      color: #000;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      &:hover {
        color: #00158b;
      }
    }

    .owl-carousel .owl-nav button i {
      font-size: 31px;
      display: block;
      line-height: 12px;
    }

    .owl-theme .owl-nav {
      position: absolute;
      top: 60px;
      line-height: 12px;
      height: 12px;
      #{$ltr} & {
        right: -20%;
      }
      #{$rtl} & {
        left: -20%;
      }
      margin-top: 0;
    }

    .owl-carousel .owl-nav button:hover {
      background-color: transparent;
    }

    .owl-carousel .owl-nav button.owl-next img,
    .owl-carousel .owl-nav button.owl-prev img {
      width: 31px;
    }

    .owl-carousel .owl-nav button.owl-prev {
      margin-right: 15px;
    }

    .owl-carousel .owl-nav button.owl-next {
      margin-left: 15px;
    }

    .owl-carousel .owl-nav {
      display: block;
    }
  }
}

.VisionSlider .TitleFont {
  margin-bottom: 30px;
  color: #000;
}

.VisionSlider .Img {
  overflow: hidden;
  margin-top: 50px;
  border-radius: 30px;
  height: 386px;
}

.VisionSlider .ImgBg {
  position: relative;
}

.VisionSlider .ImgBg::before {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  background-color: #fcfcfc;
  z-index: -1;
}

.VisionSlider .Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

@media (max-width: 768px) {
  .VisionSlider {
    padding: 30px 0 0 0;
    :global {
      .owl-theme .owl-nav {
        position: static;
        margin-top: 30px;
      }

      .owl-carousel.owl-drag .owl-item.active {
        #{$rtl} & {
          opacity: 1;
        }
      }
    }
  }

  .VisionSlider .TitleFont {
    margin-bottom: 20px;
  }

  .VisionSlider .Img {
    margin-top: 30px;
    height: 175px;
  }
}
