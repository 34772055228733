@import "assets/scss/vars.scss";

.Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: 0.3s ease-out;
}

.LoaderContent {
  width: 185px;
}

.Bar {
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 25px;
  color: #000;
}

.BarFill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #e22523;
}

.Persent {
  text-align: center;
  font-size: 17px;
  font-family: "sans-m";
}
