@import 'assets/scss/vars.scss';

.ServicesDetailsParagraph {
    padding: 100px 0;
}

@media only screen and (max-width: 768px) {
    .ServicesDetailsParagraph {
        padding: 30px 0;
    }
}