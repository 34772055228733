@import "assets/scss/vars.scss";

.Tit {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.Tit .LeftSide {
  flex-shrink: 0;
  max-width: 100%;
  #{$ltr} & {
    margin-right: 88px;
  }
  #{$rtl} & {
    margin-left: 88px;
  }
}
.Tit .LeftSide .Title {
  margin-bottom: 10px;
}

.Tit .LeftSide .DescFont {
  color: #e22523;
}

.BtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorMsg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backLink {
  text-transform: uppercase;
  text-decoration: underline !important;
}

.CheckBoxWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.CheckBoxWrapper .NotRobot {
  #{$ltr} & {
    margin-right: 60px;
  }
  #{$rtl} & {
    margin-left: 60px;
  }
}
.TermsCondWrapper {
  display: flex;
}
.TermsConditions {
  margin-bottom: 10px;
  text-decoration: underline !important;
}

.ErrorFileType {
  color: #e22523;
}

.ServiceForm :global(input[type="number"]) {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 768px) {
  .Tit {
    display: block;
    margin-bottom: 0;
    padding-bottom: 30px;
    // overflow: hidden;
  }

  .Tit .LeftSide {
    margin-right: 0 !important;
    overflow: hidden;
  }
  .Tit .LeftSide .DescFont {
    margin-bottom: 30px;
  }

  .CheckBoxWrapper {
    display: block;
  }

  .CheckBoxWrapper .NotRobot {
    margin-bottom: 30px;
    margin-right: 0;
  }
}