@import "assets/scss/vars.scss";

.RequestService :global(.InputGroup) {
  margin-bottom: 15px;
}
.RequestService {
  padding: 50px 0 30px;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.09);
}

.Info {
  padding: 0 50px;
}

.Info .SubTitle {
  margin-bottom: 10px;
}
.Info .DescFont {
  margin-bottom: 6px;
}

.RequestService :global(.tab-content) {
  padding: 0 50px;
}

.RequestServiceTabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}

.RequestServiceTabs :global(.nav-item) {
  display: block;
  padding: 14px;
  width: 50%;
  text-align: center;
  color: #000000;
  position: relative;
  flex-shrink: 0;
  font-size: 12px;
  color: #000000;
  #{$ltr} & {
    font-family: "Lato";
  }
  #{$rtl} & {
    font-family: "LatoB";
  }
}

.RequestServiceTabs :global(.nav-item:hover) {
  color: #00468b;
}

.RequestServiceTabs :global(.nav-item.active) {
  color: #00468b;
}

.RequestServiceTabs :global(.nav-item:first-child::after) {
  content: "";
  display: block;
  position: absolute;
  height: 13px;
  width: 1.5px;
  background-color: #cccccc;
  #{$ltr} & {
    right: 0;
  }
  #{$rtl} & {
    left: 0;
  }

  top: 50%;
  transform: translateY(-50%);
}

.RequestServiceTabs :global(.nav-item::before) {
  content: "";
  position: absolute;
  display: block;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 0;
  background-color: #00468b;
  transition: 0.3s ease;
}
:global(span.input-range__label.input-range__label--bottom-max
    .input-range__label-container) {
  display: none;
}
.RequestService :global(.tab-content) .DescFont {
  margin-bottom: 10px;
}
.RequestServiceTabs :global(.nav-item.active::before),
.RequestServiceTabs :global(.nav-item:hover::before) {
  width: 100%;
}
.RequestService :global(.Input) {
  margin-bottom: 20px;
}

.RequestService {
  position: relative;
  margin-bottom: 25px;
}
.RequestService .Submit {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
.Submit:hover {
  text-decoration: none;
  color: #e22523;
  background-color: #ffffff;
}
// .RequestService :global()
.RequestService
  :global(.input-range__label--min .input-range__label-container) {
  left: 0;
  font-size: 10px;
  line-height: 12px;
  font-family: Lato;
  color: #000;
  display: none;
}
.RequestService
  :global(.input-range__label--max .input-range__label-container) {
  left: 0;
  font-size: 10px;
  line-height: 12px;
  font-family: Lato;
  color: #000;
  display: none;
}
.RequestService :global(.input-range__label-container) {
  font-size: 10px;
  color: #000000;
  #{$ltr} & {
    right: 19px;
    left: auto;
  }
  #{$rtl} & {
    left: 10px;
    right: auto;
  }
}

.RequestService :global(.input-range) {
  margin-top: 10px;
  margin-bottom: 4px;
}
.RangeNumbers {
  margin-bottom: 10px;
}
.RangeNumbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RequestService
  :global(.input-range__label--value .input-range__label-container) {
  font-size: 10px;
  padding: 7px 10px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  top: -10px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    #{$ltr} & {
      left: 50%;
    }
    #{$rtl} & {
      left: 50%;
    }
    transform: translate(-50%, 100%);
    border: 4px solid transparent;
    border-top-color: #ffffff;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 106%);
    border: 4px solid transparent;
    border-top-color: #00000025;
  }
}

.RequestService :global(.input-range__slider) {
  width: 23px !important;
  height: 23px !important;
  margin-top: -13px;
  &::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
  &::after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    background-color: #f00;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}

.RequestService :global(input[type="number"]) {
  -moz-appearance: textfield;
}

// Applied Service
.disabledServices {
  cursor: no-drop !important;
}

@media (max-width: 768px) {
  .RequestService {
    padding-top: 43px;
  }
}
