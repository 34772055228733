@import "assets/scss/vars.scss";
@font-face {
  font-family: "Lato";
  src: url(/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "LatoB";
  src: url(/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: "LatoBl";
  src: url(/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato";
  src: url(/fonts/NotoSansArabic-Regular.ttf);
  unicode-range: U+0600-06ff;
}
@font-face {
  font-family: "LatoM";
  src: url(/fonts/NotoSansArabic-Medium.ttf);
  unicode-range: U+0600-06ff;
}

@font-face {
  font-family: "LatoB";
  src: url(/fonts/NotoSansArabic-Bold.ttf);
  unicode-range: U+0600-06ff;
}

@font-face {
  font-family: "LatoBl";
  src: url(/fonts/NotoSansArabic-Black.ttf);
  unicode-range: U+0600-06ff;
}
html {
  scroll-behavior: smooth;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
.Lato {
  font-family: Lato !important;
}

.LatoB {
  font-family: LatoB !important;
}

.LatoBl {
  font-family: LatoBl !important;
}

.RemoveM .container {
  overflow: hidden;
}

.RemoveM .row {
  margin-top: -15px;
  margin-bottom: -15px;
}
.Blue {
  color: #00158b;
}

button {
  border: 0;
}

/*-- SETTING UP THE CSS --*/
body {
  font-family: "Lato", "sans-serif";
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  #{$ltr} & {
    text-align: left;
  }
  #{$rtl} & {
    text-align: right;
  }
}
.ScrollAuto {
  overflow-y: auto;
}
#root {
  // height: 1000vh;
  #{$ltr} & {
    text-align: left;
  }
  #{$rtl} & {
    text-align: right;
  }
}
.main {
  min-height: calc(100vh - 502px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main > * {
  width: 100%;
}
p {
  margin: 0;
  color: black;
}

a {
  color: black;
  text-decoration: none !important;
  transition: all 0.3s;
}

a:hover {
  color: #e22523;
}

a .active {
  color: #e22523;
}
button {
  outline: none !important;
}
.Active {
  color: #e22523;
}
*::placeholder {
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.gm-style-iw.gm-style-iw-c {
  padding: 0;
  border-radius: 30px;
}
.gm-style-iw.gm-style-iw-c button {
  top: 7px !important;
  right: 7px !important;
}
.gm-style-iw.gm-style-iw-c button img {
  width: 17px !important;
  height: 20px !important;
}
.InfoWindow {
  max-width: 255px;
  padding: 40px 30px;
}
.InfoWindow .SubTitle {
  margin-bottom: 20px;
  font-family: LatoB;
}
.InfoWindow .Other {
  line-height: 24px;
}

.owl-carousel {
  direction: ltr;
  #{$rtl} & {
    direction: rtl;
  }
}
// body[dir="rtl"] .owl-stage {
//   transform: translate3d(216px, 0px, 0px) !important;
// }
body[dir="rtl"] .owl-carousel {
  direction: ltr;
}
// .owl-carousel .owl-item img {
//   width: auto;
// }
/*---DEFAULT CLASSES---*/

.TitleContainer {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TitleContainer > * {
  padding-bottom: 0 !important;
}
.TitleFont {
  font-family: LatoBl;
  font-size: 30px;
  line-height: 36px;
  color: #000;
}

.SubTitle {
  font-size: 24px;
  line-height: 29px;
  #{$ltr} & {
    font-family: LatoBl;
  }
  #{$rtl} & {
    font-family: LatoB;
  }
  color: #000;
}

.FooterTabs {
  font-family: LatoB;
  font-size: 18px;
  line-height: 24px;
}

.DescFont {
  font-size: 12px;
  line-height: 17px;
  color: #000;
  #{$ltr} & {
    font-family: Lato;
  }
  #{$rtl} & {
    font-family: "LatoM";
  }
}
.WhiteFont {
  color: #ffffff;
}
.TopFont {
  color: #e22523;
  font-family: latoBl;
  font-size: 48px;
  line-height: 57px;
}

.Other {
  font-size: 14px;
  font-family: Lato;
  line-height: 16px;
  color: #000;
}

.OtherB {
  font-size: 14px;
  font-family: LatoB;
  line-height: 16px;
  color: #000;
}
.OtherBl {
  font-size: 14px;
  font-family: LatoBl;
  line-height: 16px;
  color: #000;
}

.Font10 {
  font-size: 10px;
  line-height: 12px;
  font-family: Lato;
  color: #000;
}

.AddPadding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.GreyBg {
  background-color: #fcfcfc;
}
.SectionPadding {
  padding: 100px 0;
}
.Button {
  width: 160px;
  height: 50px;
  background-color: #e22523;
  font-family: Lato;
  color: white;
  border: 1px solid #e22523;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  transition: all 0.3s;
}

.SquareButton {
  width: 100%;
  height: 78px;
  background-color: #ffffff;
  font-family: LatoB;
  color: black;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 17px;
  transition: all 0.3s;
}
.SubPadding {
  padding-bottom: 50px;
  text-transform: capitalize;
}

.SquareButton:hover {
  color: #e22523;
  box-shadow: 0 0 34px 7px rgba(0, 0, 0, 0.03);
}

.Button.White {
  background-color: white;
  color: #e22523;
}

.Button:hover {
  color: #e22523;
  background-color: white;
}

.Button.White:hover {
  color: white;
  background-color: #e22523;
}

.Orange {
  color: #e22523;
}
.SuccessHint {
  opacity: 0.5;
}

// REMOVE MODAL DEFULT STYLING
.modal-content {
  border: none !important;
  #{$rtl} & {
    text-align: right;
  }
}
.modal-dialog {
  max-width: 100%;
}

.modal-backdrop {
  background-color: #fff;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.DropBtn {
  position: relative;
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  #{$ltr} & {
    padding-right: 30px !important;
  }
  #{$rtl} & {
    padding-left: 30px !important;
  }
}
.DropBtn::before {
  content: "\e911";
  font-family: "icomoon";
  display: block;
  color: #000000;
  font-size: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  #{$ltr} & {
    right: 12px;
  }
  #{$rtl} & {
    left: 12px;
  }
}
.dropdown.show .DropBtn::before {
  transform: translateY(-50%) rotate(180deg);
}
.DropMenu {
  padding: 10px 0;
  border: 0;
  width: 100%;
  transition: 0.3s ease-out;
  opacity: 0;
  //   will-change: transform, opacity, top !important;
  top: 20px;
  display: block !important;
  visibility: hidden;
}
.dropdown span::before,
.dropdown span::after {
  transition: 0.3s ease-out;
}
.DropMenu.show {
  opacity: 1;
  visibility: visible;
  top: 0;
}
.DropInner {
  min-width: 100%;
  border-radius: 25px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  padding: 15px 30px;
}
.DropMenu .DropItem {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  #{$rtl} & {
    text-align: right;
  }
}
.DropMenu .DropItem:hover,
.DropMenu .DropItem.active {
  color: #e22523;
}
.DropMenu .DropItem:last-child {
  border-bottom: 0;
}

.PhoneRow {
  display: flex;
  align-items: center;
}
.PhoneRow .dropdown {
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
}

.dropdown-menu:not(.TableDrop) {
  #{$rtl} & {
    text-align: right;
    right: 0 !important;
    left: auto !important;
  }
}
.dropdown-menu {
  transition: none !important;
}
input,
select,
textarea {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  outline: none;
}

.Input,
.Input:disabled {
  width: 100%;
  height: 43px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  padding: 0 14px;
  border-radius: 50px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5);
}
textarea.Input {
  height: 150px;
  resize: none;
  padding: 14px;
  border-radius: 25px;
}
.Hint {
  font-size: 12px;
  line-height: 15px;
  color: #707070;
  opacity: 0.5;
  margin-top: 10px;
}
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker {
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid rgba(112, 112, 112, 0.2);
}
.react-datepicker * {
  font-weight: bold;
}
.react-datepicker__header {
  background-color: #ffffff;
  border: 0;
  padding-top: 17px;
}
.react-datepicker__navigation {
  top: 20px;
  border: 0;
  text-indent: inherit;
  width: 9px;
  height: 14px;
}
.react-datepicker__navigation::before {
  content: "\e911";
  font-family: "icomoon";
  display: block;
  color: #000000;
  font-size: 10px;
  line-height: 10px;
}
.react-datepicker__navigation--next::before {
  transform: rotate(-90deg);
}
.react-datepicker__navigation--previous::before {
  transform: rotate(90deg);
}
.InputGroup {
  position: relative;
  margin-bottom: 30px;
}
.InputGroup.RemoveMarginBottom {
  margin-bottom: 0;
}
label {
  margin-bottom: 10px;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
}

.DatePicker {
  position: relative;
}

.icon-calendar {
  font-size: 21px;
  position: absolute;
  top: calc(50% - 2px);
  #{$ltr} & {
    right: 17px;
  }
  #{$rtl} & {
    left: 17px;
  }
  transform: translateY(-50%);
  color: #002b69;
  pointer-events: none;
}

.Input::placeholder {
  font-family: lato;
  font-size: 12px;
  line-height: 17px;
}

.radio-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #707070;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio-span:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: transparent;
  border-radius: 100%;
  transition: all 0.3s;
}
.SelectCheck .check-span {
  margin-right: 10px;
}
input[type="radio"]:checked + .radio-span:before {
  background-color: #e22523;
}

.Input.Input[type="number"]::-webkit-inner-spin-button,
.Input.Input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.check-input {
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #ededed;
}

.check-span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px solid #eee;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
}
.CheckLabel {
  margin: 0 10px;
  cursor: pointer;
}
.LogBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.GlobalErr {
  position: absolute;
  top: 8px;
  color: #e22523;
  #{$ltr} & {
    right: 0;
  }
  #{$rtl} & {
    left: 0;
  }
}
.CheckBox {
  display: flex;
  align-items: center;
}
input[type="checkbox"]:checked + .check-span {
  background-color: #e22523;
  border: 1px solid #e22523;
}
.checkCheckBox {
  background-color: #e22523;
  border: 1px solid #e22523;
}
.check-span:after {
  content: "\e906";
  visibility: hidden;
  font-family: "icomoon" !important;
  color: white;
  font-size: 8px;
  font-weight: bold;
  line-height: 5px;
  width: 7px;
}

/* Show the checkmark when checked */
input[type="checkbox"]:checked + .check-span:after {
  visibility: visible;
}
input[type="checkbox"] {
  display: none;
}
.text-area {
  width: 100%;
  height: 141px;
  border: 1px solid #ededed;
  padding: 9px 14px;
  border-radius: 30px;
  resize: none;
}

.text-area::placeholder {
  font-family: lato;
  font-size: 12px;
  line-height: 17px;
}

.Input-error {
  border: 1px solid #e22523;
}

label.Required {
  display: flex;
}
label.Required:after {
  content: "*";
  color: #e22523;
  font-size: 17px;
  display: block;
  margin-top: 2px;
  height: 10px;
  #{$ltr} & {
    margin-left: 2px;
  }
  #{$rtl} & {
    margin-right: 2px;
  }
}

/*-- INPUT:TYPE FILE --*/
input[type="file"] {
  display: none;
}

label.Input {
  display: flex;
  align-items: center;
}

label.Input span {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// NORIFICATION ON-OFF
.SetNotification {
  position: relative;
  width: 30px;
  height: 10px;
  background-color: #ededed;
  border-radius: 25px;
  transition: all 0.3s;
}

.SetNotification.active {
  background-color: #e22523;
}

.SetNotification .circle {
  position: absolute;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.16);
  left: 0;
  border-radius: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  cursor: pointer;
}

.SetNotification.active .circle {
  background-color: #ffffff;
  left: 100%;
}

.owl-carousel .item {
  #{$rtl} & {
    direction: rtl;
  }
}

.ModalLeftButton {
  position: absolute;
  bottom: 0;
  #{$ltr} & {
    left: 95px;
  }
  #{$rtl} & {
    right: 95px;
  }
  transform: translateY(50%);
  outline: none;
}

.ModalRightButton {
  position: absolute;
  bottom: 0;
  #{$ltr} & {
    right: 95px;
  }
  #{$rtl} & {
    left: 95px;
  }
  transform: translateY(50%);
  outline: none;
}

.InputGroup .Label {
  margin-bottom: 10px;
}

.Pointer {
  cursor: pointer;
  word-break: break-word;
}
.err .icon {
  font-size: 11px;
  #{$ltr} & {
    margin-right: 10px;
  }
  #{$rtl} & {
    margin-left: 10px;
  }
}
.err {
  color: #e22523;
  // position: absolute;
  position: relative;
  margin-top: 5px;
  top: 0;
  #{$ltr} & {
    right: 0;
  }
  #{$rtl} & {
    left: 0;
  }
  font-size: 12px;
  font-family: "Lato";
  line-height: 15px;
}
.container.footerContainer {
  position: relative;
}

.ScrollUp {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  box-shadow: 0 10px 16px rgba(226, 37, 35, 0.4);
  #{$ltr} & {
    right: 15px;
  }
  #{$rtl} & {
    left: 15px;
  }
  top: 0;
  transform: translateY(-50%);
}
.ScrollUp .icon {
  font-size: 10px;
  font-size: 15px;
}

.RequestSubmitted {
  margin-bottom: 10px;
}
.TableLastTd table tr td:last-child {
  width: 30%;
}

.arrow_up {
  transform: rotate(180deg);
}

button:disabled {
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-right: 32px;
    padding-left: 32px;
  }
  .AddPadding {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .TopFont {
    font-size: 30px;
    line-height: 36px;
  }
  .SectionPadding {
    padding: 30px 0;
  }
  .SubPadding {
    padding-bottom: 30px;
  }
  .TitleContainer {
    margin-bottom: 30px;
  }
  .LogBtns {
    flex-direction: column;
  }
  .LogBtns .DescFont {
    margin: 30px 0;
  }
  .LogBtns .Button {
    width: 100%;
  }

  .GlobalErr {
    position: static;
    margin-bottom: 30px;
  }
  .SignOutModal {
    display: inline-block;
    margin-bottom: 30px;
  }

  .ModalLeftButton {
    position: static;
    margin-bottom: 33px; //In Notification Modal [Responsive] btn margin-bottom should be 0
    width: 100%;
    transform: none;
  }
  .ModalRightButton {
    position: static;
    width: 100%;
    transform: none;
  }
  .ScrollUp {
    #{$ltr} & {
      right: 35px;
    }
    #{$rtl} & {
      left: 35px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .ScrollUp {
    #{$ltr} & {
      right: 15px;
    }
    #{$rtl} & {
      left: 15px;
    }
  }
}

.radioBtnAlign {
  flex-wrap: wrap;
}
