@import "assets/scss/vars.scss";

.Wrapper {
  padding-bottom: 25px;
  margin-bottom: 30px;
  // margin-top: 15px;
}

.Offers {
  width: 100%;
  padding: 30px 30px 50px 30px;
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.04);
  position: relative;
}

.Offers:hover {
  box-shadow: 0px 0px 100px -15px rgba(0, 14, 92, 0.15);
}

.OffersContent {
  display: flex;
  align-items: flex-start;
}

.OffersContent .Img {
  width: 101px;
  height: 101px;
  flex-shrink: 0;
  border: 1px solid rgba(112, 112, 112, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
}

.OffersContent .Img img {
  width: 61px;
}

.Offers .Button {
  position: absolute;
  bottom: 0;
  #{$ltr} & {
    left: 97px;
  }
  #{$rtl} & {
    right: 97px;
  }
  transform: translateY(50%);
}
.OffersContent .Desc {
  margin-bottom: 20px;
}

.OffersContent .Date {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.OffersContent .Text {
  margin-bottom: 20px;
}

.OffersContent .Text:last-child {
  margin-bottom: 0;
}

.OffersContent .Text p {
  margin-bottom: 10px;
}

.OffersContent .Text p:last-child {
  margin-bottom: 0;
}

.OffersContent .Text ul {
  padding: initial;
  margin: auto;
  list-style: initial;
}

.OffersList {
  margin: 0 0 20px 0;
  #{$ltr} & {
    padding-left: 15px;
  }
  #{$rtl} & {
    padding-right: 15px;
  }
}

// Applied Service
.disabledServices {
  cursor: no-drop !important;
}

.disabledServices:hover {
  opacity: 0.5;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .OffersContent {
    flex-direction: column;
    align-items: center;
  }
  .OffersContent .Img {
    margin-bottom: 20px;
    width: 200px;
    #{$ltr} & {
      margin-right: 0 !important;
    }
    #{$rtl} & {
      margin-left: 0 !important;
    }
  }
}
