@import "assets/scss/vars.scss";

.SideBarContent {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
}

.SideBarContent:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.SideBarContent .SideLink {
  color: #000;
  text-transform: capitalize;
}

.SideBarContent.active {
  color: #e22523;
}
.SideBarContent.active span {
  color: #e22523;
}
.SideBarContent:hover .SideIcon,
.SideBarContent:hover .SideLink {
  color: #e22523;
}

.SideBarContent .EditIcon {
  #{$ltr} & {
    display: flex;
  }
}

.SideBar .SideBarContent.active a,
.SideBar .SideBarContent.active i {
  color: #e22523;
}

.SideIcon {
  font-size: 16px;
  // transition: all 0.3s; // hashing it to avoid delay of transition on icons in side bar
  #{$ltr} & {
    margin-right: 18px;
  }
  #{$rtl} & {
    margin-left: 18px;
  }
}

.iconImage {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-self: center;
  #{$ltr} & {
    margin-right: 18px;
  }
  #{$rtl} & {
    margin-left: 18px;
  }
  flex-shrink: 0;
}

.iconImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.iconImage img:last-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.SideBarContent:hover .iconImage img:first-child {
  opacity: 0;
  visibility: hidden;
}

.SideBarContent:hover .iconImage img:last-child {
  opacity: 1;
  visibility: visible;
}

.SideBarContent.active .iconImage img:first-child {
  opacity: 0;
  visibility: hidden;
}

.SideBarContent.active .iconImage img:last-child {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  :global(.SideTitleResp) .SideBarContent.active {
    display: block;
  }
  .SideBarContent.active {
    display: none;
    vertical-align: auto;
  }
}
