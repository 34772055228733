@import "assets/scss/vars.scss";

// .Faq {
//   margin-bottom: 100px;
// }
.Collapse {
  display: block;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  cursor: pointer;
}

.Collapse:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.CollapseDesc {
  margin-top: 30px;
}

.Collapse .CollapseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Collapse .CollapseHeader span {
  font-family: latoB;
}

.SandwichIcon {
  cursor: pointer;
  position: relative;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
}

.SandwichIcon span {
  display: block;
  height: 2px;
  width: 22px;
  background-color: #000000;
  position: absolute;
  transition: all 0.4s;
}
.SandwichIcon span:first-child {
  transform: rotate(90deg);
}
.CollapseHeader.active .SandwichIcon span:first-child,
.CollapseHeader.active .SandwichIcon span:last-child {
  transform: rotate(180deg);
  white-space: inherit;
}
@media only screen and (max-width: 768px) {
  // .Faq {
  //   margin-bottom: 30px;
  // }
  .Tit {
    margin-bottom: 30px;
  }
  .Collapse .CollapseHeader span {
    max-width: 234px;
  }
  .CollapseDesc {
    margin-top: 16px;
  }
}
