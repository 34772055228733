@import "assets/scss/vars.scss";

.ContactInfo .ContactSection {
  margin-bottom: 30px;
}

.ContactInfo .ContactSection:last-child{
  margin-bottom:0;
}

.ContactInfo .ContactSection .Tit {
  margin-bottom: 20px;
}

.ContactInfo .ContactEmail {
  position: relative;
  #{$ltr} & {
    padding-left: 20px;
  }
  #{$rtl} & {
    padding-right: 20px;
  }
  margin-bottom: 20px;
}

.ContactInfo .ContactEmail:last-child {
  margin-bottom: 0;
}

.ContactInfo .ContactEmail::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e22523;
  position: absolute;
  border-radius: 100%;
  top: 50%;
  #{$ltr} & {
    left: 0;
  }
  #{$rtl} & {
    right: 0;
  }
  transform: translateY(-50%);
}

.ContactInfo .Location {
  line-height: 30px !important;
}

.ContactInfo .PhoneNum,
.ContactInfo .FaxNum {
  margin-bottom: 20px;
}
.ContactInfo .PhoneNum:last-child,
.ContactInfo .FaxNum:last-child {
  margin-bottom: 0;
}

@media (max-width:768px) {
  .ContactInfo .ContactSection:last-child{
    margin-bottom:30px;
  }
}