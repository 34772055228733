@import "assets/scss/vars.scss";

.HeadSlider {
	padding: 0;
}
.HeadSlider :global(.TopFont) {
	margin-bottom: 10px;
}
.HeadSlider :global(.DescFont.WhiteFont) {
	margin: 20px 0 30px;
}
.img {
	border-radius: 15px;
	height: 315px;
	width: 100%;
	object-fit: cover;
}
.HeadSlider :global(.owl-carousel) {
	padding-top: 55px;
}
.HeadSlider :global(.owl-theme .owl-dots) {
	position: absolute;
	top: 0;
	#{$ltr} & {
		left: 0;
	}
	#{$rtl} & {
		right: 0;
		direction: rtl;
	}
	display: flex;
	margin: 0 !important;
}
.HeadSlider :global(.owl-theme .owl-dots .owl-dot) {
	vertical-align: bottom;
	height: 47px;
	outline: none;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.HeadSlider :global(.owl-theme .owl-dots .owl-dot) span {
	background-color: #ffffff;
	height: 7px;
	width: 2px;
	vertical-align: bottom;
	transition: 0.2s ease-out;
}
.HeadSlider :global(.owl-theme .owl-dots .owl-dot.active) span {
	background-color: #e22523;
	height: 13px;
	width: 2px;
}
.HeadSlider :global(.owl-dot) dev {
	color: #e22523;
	font-size: 12px;
	opacity: 0;
	transition: 0.2s ease-out;
}
.HeadSlider :global(.owl-dot.active) dev {
	color: #e22523;
	font-size: 12px;
	opacity: 1;
}
.HeadSlider :global(.owl-stage-outer) {
	padding-bottom: 25px;
}
.HeadSlider :global(.item) {
	position: relative;
}
.HeadSlider :global(.Button) {
	position: absolute;
	bottom: 0;
	#{$ltr} & {
		right: 15%;
	}
	#{$rtl} & {
		left: 15%;
	}
	transform: translateY(50%);
}

.SliderDesc,
.SliderDesc p {
	font-size: 15px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	p {
		color: #fff;
	}
}

.HeadSlider :global(.Button) i {
	#{$ltr} & {
		margin-right: 5px;
	}
	#{$rtl} & {
		margin-left: 5px;
	}
}

@media only screen and (max-width: 768px) {
	.HeadSlider {
		margin-bottom: 30px;
	}
	.img {
		height: 175px;
	}
	.HeadSlider :global(.Button) {
		right: 50% !important;
		transform: translate(50%, 50%);
	}
}
