@import "assets/scss/vars.scss";

.WithdrawalCases {
  width: 100%;
  padding: 30px;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}

.WithdrawalCases .Tit {
  font-family: latoB !important;
  transition: all 0.3s;
  text-transform: uppercase;
}
.WithdrawalCases:hover {
  box-shadow: 0 0 34px 7px rgba(0, 0, 0, 0.03);
}
.WithdrawalCases:hover .Tit {
  color: #e22523;
}

.WithdrawalCases .Desc {
  color: #e22523;
  #{$ltr} & {
    margin-left: 30px;
  }
  #{$rtl} & {
    margin-right: 30px;
  }
}
