@import "assets/scss/vars.scss";

.SignDropDown:global(.dropdown) {
  :global {
    .dropdown-menu {
      text-align: center !important;
      padding: 11px 30px;
      width: 255px;
      border-radius: 25px;
      top: 50px;
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      box-shadow: 0px 0px 100px -15px rgba(0, 14, 92, 0.15);
      #{$ltr} & {
        // right: 0 !important;
        // left: auto !important;
      }
      #{$rtl} & {
        left: 0 !important;
        right: auto !important;
      }
    }
    .dropdown-menu.show {
      top: 10px !important;
      opacity: 1;
      visibility: visible;
    }
  }
  .SignLink {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #ededed;
    cursor: pointer;
    &:last-child {
      border-bottom: 0;
    }
    a {
      display: inline-block;
      width: 100%;
    }
  }
}
