@import "assets/scss/vars.scss";

.Testimonials {
  overflow: hidden;
}
.Testimonials :global(.container) {
  position: relative;
}
.Testimonials :global([class*="col-"]),
.Testimonials :global(.owl-carousel) {
  position: static;
}
.Testimonials :global(.owl-theme .owl-nav) {
  position: absolute;
  #{$ltr} & {
    right: 15px;
  }
  #{$rtl} & {
    left: 15px;
  }
  top: 5px;
  font-size: 31px;
  margin: 0;
  height: 20px;
  line-height: 20px;
}
.Testimonials :global(.owl-theme .owl-nav button.owl-prev),
.Testimonials :global(.owl-theme .owl-nav button.owl-next) {
  width: auto;
  padding: 0 !important;
  margin: 0;
  height: 20px;
  background-color: transparent;
  color: #000000;
  transition: 0.3s;
}
.Testimonials :global(.owl-theme .owl-nav button.owl-prev:hover),
.Testimonials :global(.owl-theme .owl-nav button.owl-next:hover) {
  color: #00158b;
}
.Testimonials :global(.owl-theme .owl-nav button.owl-prev.disabled),
.Testimonials :global(.owl-theme .owl-nav button.owl-next.disabled) {
  opacity: 0;
  visibility: hidden;
}
.Testimonials :global(.owl-theme .owl-nav button.owl-prev i),
.Testimonials :global(.owl-theme .owl-nav button.owl-next i) {
  line-height: 20px;
  display: block;
}
.Testimonials :global(.owl-theme .owl-nav button.owl-next) {
  margin-left: 30px;
}
.Testimonials :global(.owl-stage-outer) {
  overflow: visible;
}
.Testimonials :global(.owl-item) {
  opacity: 0.2;
}
.Testimonials :global(.owl-item.active) {
  opacity: 1;
}

.TestiBox {
  position: relative;
}

.TestiBox .Qoutes {
  width: 64px !important;
  #{$rtl} & {
    display: inline-block;
  }
}

.TestiBox .Qoutes:first-child {
  margin-bottom: 20px;
}

.TestiBox .Qoutes.BottomQoute {
  position: absolute;
  bottom: 30px;
  #{$ltr} & {
    right: 0;
  }
  #{$rtl} & {
    left: 0;
  }
}

.TestiBox .Tit {
  margin-bottom: 20px;
}

.UserLine {
  display: flex;
  align-items: center;
  #{$rtl} & {
    direction: rtl;
    justify-content: flex-start;
  }
}

.UserLine .Img {
  display: block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  #{$ltr} & {
    margin-right: 10px;
  }
  #{$rtl} & {
    margin-left: 10px;
  }
}

.UserLine img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserLine .Name {
  margin-bottom: 8px;
  font-family: "LatoB";
}

@media only screen and (max-width: 768px) {
  .TestiBox .Tit {
    margin-bottom: 87px;
  }

  .TestiBox .Qoutes.BottomQoute {
    bottom: 100px;
  }

  .Testimonials :global(.owl-theme .owl-nav) {
    position: static;
    margin-top: 30px;
  }
}
