@import "assets/scss/vars.scss";

.ClientBox {
  height: 226px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 15px;
  margin-bottom: 30px;
}

.ClientBox img {
  max-width: 169px;
}

@media only screen and (max-width: 768px) {
  .ClientBox {
    height: 142px;
  }

  .ClientBox img {
    width: 80%;
  }
}
