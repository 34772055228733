@import "assets/scss/vars.scss";

.NoSubmition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.NoSubmition .NoSubmitionTitle {
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.NoSubmition .ApplyBtn {
  margin: 0 auto;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .NoSubmition {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
  }

  .NoSubmition .NoSubmitionTitle {
    white-space: normal;
    word-break: break-word;
  }
}
