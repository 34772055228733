@import "assets/scss/vars.scss";

.NewsPageDetails {
  padding: 30px 0 100px;
  :global {
    .SocialShareIcon {
      margin-bottom: 30px;
    }
  }
}

.NewsPageDetails .Date {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.NewsPageDetails .Tit {
  margin-bottom: 20px;
  word-break: break-word;
}

.NewsPageDetails .Desc {
  margin-top: 30px;
  word-break: break-word;
}

.NewsPageDetails .Desc p {
  margin-bottom: 10px;
}

.NewsPageDetails .Desc p:last-child {
  margin-bottom: 0;
}

.NewsPageDetails .Desc ul {
  padding: initial;
  margin: auto;
  list-style: initial;
}

@media only screen and (max-width: 768px) {
  .NewsPageDetails {
    padding: 30px 0 30px;
    :global {
      .SocialShareIcon {
        position: relative;
        #{$ltr} & {
          left: 0;
        }
        #{$rtl} & {
          right: 0;
        }
      }
    }
  }
  .NewsDetails {
    position: relative;
    #{$ltr} & {
      left: 0;
    }
    #{$rtl} & {
      right: 0;
    }
  }
}
