@import "assets/scss/vars.scss";

.ProfileSubmitFacility {
  padding-top: 100px;
  padding-bottom: 100px;
}

.WithdrawalSection {
  margin-bottom: 20px;
}
.WithdrawalSection:last-child {
  margin-bottom: 0;
}
.WithdrawalSection .Tit {
  margin-bottom: 50px;
  text-transform: uppercase;
}

.WithdrawalSection .Services {
  margin-bottom: 30px;
  text-transform: uppercase;
}

// Applied Service
.disabledServices {
  cursor: no-drop !important;
  margin-bottom: 30px;
  text-transform: uppercase;
}

:global(.SquareButton).disabledServices:hover {
  opacity: 0.5;
  color: #000;
}

.BankLabel {
  margin-bottom: 50px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .WithdrawalSection .Tit {
    margin-bottom: 30px;
  }
  .ProfileSubmitFacility {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .WithdrawalSection {
    margin-bottom: 0;
  }
  .BankLabel {
    margin-bottom: 30px;
  }
}
