@import "assets/scss/vars.scss";

.TermsConditions {
	padding: 100px 0;
}

.TermsConditions h4 {
	font-family: LatoB;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 10px;
}

.TermsConditions h4:not(:first-child) {
	margin-top: 20px;
}

.TermsConditions p + p {
	margin-top: 5px;
}

.TermsConditions h4 + h5 {
	font-family: LatoB;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
}

.TermsConditions :global(.container ul) {
	list-style: initial;
}

@media only screen and (max-width: 768px) {
	.TermsConditions {
		padding: 30px 0;
	}
}
