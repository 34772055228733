@import "assets/scss/vars.scss";

.NavItems {
  display: flex;
  align-items: center;
}
.NavItems.Responsive {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.NavItems.Responsive li {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100%;
  margin-bottom: 30px;
  &:first-child {
    margin-top: 30px;
  }
}
.NavItems.Responsive li .NavLink {
  font-size: 24px;
}

.NavItems li {
  position: relative;
  // padding-bottom: 5px;
  #{$ltr} & {
    margin-right: 39px;
  }
  #{$rtl} & {
    margin-left: 39px;
  }
}

.NavItems li:last-child {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$rtl} & {
    margin-left: 0;
  }
}

.NavItems li .NavLink {
  color: white;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: capitalize;
}
.NavItems li .NavLink.active,
.NavItems li .NavLink:hover {
  color: #e22523;
}

.SideSign {
  display: flex;
  align-items: center;
}
.SideSign .Sign {
  width: 50%;
  display: block;
  font-size: 24px;
  font-family: LatoB;
  color: #ffffff;
  cursor: pointer;
}
.NavItems :global(.dropdown > .SignLink) {
  padding: 20px 0;
  font-size: 12px;
  color: #ffffff;
}
.NavItems :global(.dropdown.show > .SignLink::after) {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .LangTitle {
    font-size: 24px;
    line-height: 29px;
    font-family: "LatoB";
    color: #fff;
  }
}
