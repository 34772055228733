@import "assets/scss/vars.scss";

.ChangePassword {
}

.Wrapper {
  padding-bottom: 18px;
}
.ProfileImg {
  width: 100%;
  height: 160px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}

.ProfileImg .Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Holder {
  width: 160px;
  max-width: 100%;
  height: 160px;
  position: relative;
}

.Camera {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
  transition: all 0.3s;
}

.Camera:hover {
  background-color: rgba(0, 15, 96, 0.3);
}

.Camera span {
  display: inline-bolock;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  margin-bottom: 0;
  transition: all 0.3s;
  width: 36px;
  height: 36px;
  background: #e22523;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

.Camera input {
  display: none;
}

.Camera .UploadIcon {
  font-size: 17px;
  color: #ffffff;
}

.Holder:hover .Camera span {
  bottom: 50%;
}

@media (max-width: 768px) {
  .Wrapper {
    margin-bottom: 30px;
  }

  .EditForm :global(.LogBtns) {
    flex-direction: row;
    :global(.Button):first-child {
      #{$ltr} & {
        margin-right: 15px;
      }
      #{$rtl} & {
        margin-left: 15px;
      }
    }
    :global(.Button):last-child {
      #{$ltr} & {
        margin-left: 15px;
      }
      #{$rtl} & {
        margin-right: 15px;
      }
    }
  }
}
