@import "assets/scss/vars.scss";

.LocationDropDown:global(.dropdown.show .SignLink) {
  border-bottom-color: transparent;
}
.LocationDropDown {
  :global {
    .SignLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ededed;
      border-bottom-color: #ededed !important;
      &:after {
        content: "\e911";
        display: block;
        font-family: "icomoon" !important;
        font-size: 9px;
      }
    }
    .dropdown.show {
      .SignLink {
        border-bottom-color: transparent;
      }
    }
    .dropdown-menu {
      padding-bottom: 11px !important;
      width: 195px !important;
      top: 50px !important;
      text-align: center;
      border-radius: 25px;
      box-shadow: 0px 0px 100px -15px rgba(0, 14, 92, 0.15);
      min-width: 100%;
      .SignLink {
        justify-content: center;
        padding: 20px 0;
        border-bottom: 1px solid #ededed;
        cursor: pointer;
        &:after {
          display: none;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .dropdown-menu.show {
      top: -5px !important;
    }
  }
}
:global(.dropdown.show > .SignLink::after) {
  transform: rotate(180deg);
}
.flag {
  width: 19px;
  #{$ltr} & {
    margin-right: 10px;
  }
  #{$rtl} & {
    margin-left: 10px;
  }
}
