@import "assets/scss/vars.scss";

.Tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Download {
  color: #e22523 !important;
  cursor: pointer;
  display: flex;
}
.Download .DownloadIcon {
  #{$ltr} & {
    margin-right: 11px;
  }
  #{$rtl} & {
    margin-left: 11px;
  }
  transform: rotate(90deg);
}
.Download a {
  color: #e22523;
}
.Download p {
  color: #e22523;
}
.CaseId {
  color: #e22523 !important;
  display: inline-block;
  margin-bottom: 50px;
  font-family: latoB;
}
.TableWrapper {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
.TableWrapper table {
  width: 100%;
}

.TableWrapper table tr {
  vertical-align: top;
}

.TableWrapper table tr td {
  padding-bottom: 30px;
}

.TableWrapper table tr td:first-child {
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  // white-space: nowrap;
  #{$ltr} & {
    padding-right: 10px;
  }
  #{$rtl} & {
    padding-left: 10px;
  }
}
.TableWrapper table tr td:last-child {
  color: rgba(0, 0, 0, 0.5) !important;
  font-family: LatoB;
  font-size: 12px;
  line-height: 15px;
  width: 40%;
  word-break: break-word;
}
.RadioGroup {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 25px;
}

.NotRadiobtnSelected {
  margin-bottom: 0;
}

.Radio {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #707070;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  #{$ltr} & {
    margin-right: 10px;
  }
  #{$rtl} & {
    margin-left: 10px;
  }
}
.Radio:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: transparent;
  border-radius: 100%;
  transition: all 0.3s;
}
.Tab.active .Radio:before {
  background-color: #e22523;
}
.Tabs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 25px;
  position: relative;
}
.Tabs .Tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.DownloadLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.DownloadLinks .Download:first-child {
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
}

.CommentDate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.CommentDate div:last-child {
  white-space: nowrap;
  #{$ltr} & {
    margin-left: 20px;
  }
  #{$rtl} & {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .RadioGroup {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 15px;
  }
  .Tabs {
    flex-direction: column;
    align-items: flex-start;
  }
  .Tabs .Tab:first-child {
    margin-bottom: 30px;
  }
  .CommentDate div:last-child {
    #{$ltr} & {
      margin-left: 10px;
    }
    #{$rtl} & {
      margin-right: 10px;
    }
  }
  .TableWrapper table tr td:last-child {
    width: 50%;
  }
}
