@import "assets/scss/vars.scss";

.RejectRequests {
}

.ProviderApp {
  padding-top: 100px;
  padding-bottom: 100px;
}
.WithdrawalSection {
  margin-bottom: 20px;
}
.WithdrawalSection:last-child {
  margin-bottom: 0;
}
.WithdrawalSection .Tit {
  margin-bottom: 50px;
}

.WithdrawalSection .Services {
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
  .WithdrawalSection .Tit {
    margin-bottom: 30px;
  }
  .ProviderApp {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .WithdrawalSection {
    margin-bottom: 0;
  }
}
