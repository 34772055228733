@import "assets/scss/vars.scss";

.ProfileBankOffers {
  padding-top: 100px;
  padding-bottom: 100px;
}

:global(.noData) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-family: LatoB;
  font-size: 20px;
  color: #e22523;
}

@media only screen and (max-width: 768px) {
  .ProfileBankOffers {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  :global(.noData) {
    font-size: 14px;
    font-family: Lato;
  }
}
