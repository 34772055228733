@import "assets/scss/vars.scss";

.AcceptWithdrawal {
}

:global(.InputGroup) {
  :global(.InputGroup) {
    margin-bottom: 0;
  }
}
