@import "assets/scss/vars.scss";

.CoreValuesContent {
  padding: 70px 0;
  background-color: #fcfcfc;
  :global {
    .TitleFont {
      margin-bottom: 50px;
      text-transform: capitalize;
    }
  }
}

.CoreValuesContent .TitleFont {
  color: #000;
}

.CoreValues {
  margin-bottom: 30px;
}

.CoreValues .Img {
  height: 55px;
  margin-bottom: 20px;
}

.OurTeamSlider {
  padding: 100px 0;
  overflow: hidden;
  :global {
    .TitleFont {
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 768px) {
  .OurVision {
    padding: 55px 0 30px 0;
  }

  .CoreValuesContent {
    padding: 30px 0 0;
    :global {
      .TitleFont {
        margin-bottom: 30px;
      }
    }
  }

  .CoreValues .Tit {
    font-size: 18px;
  }
}
