@import "assets/scss/vars.scss";

.ResultsFound {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}
.ResultsFound span {
  display: inline-block;
  #{$ltr} & {
    margin-right: 12px;
  }
  #{$rtl} & {
    margin-left: 12px;
  }
}
.ResultsFound span:last-child {
  margin-right: 0;
}
.ResultsFound .Separator {
  width: 55px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}
.SectionPadding {
  margin-bottom: 70px;
}
.Tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.NoResultsFound {
  color: #e22523;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  font-family: "LatoBl";
  margin-top: 10px;
}

.PersonalServices {
  margin-bottom: 20px;
}

.PersonalServices .Title,
.CorporateServices .Title {
  margin-bottom: 10px;
}

.NoResults {
  color: #000;
  font-size: 30px;
  line-height: 36px;
  font-family: "LatoBl";
  margin: 100px 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .ResultsFound {
    margin-bottom: 30px;
  }
  .SectionPadding {
    margin-bottom: 0;
    // overflow: hidden;
  }
  .Tit {
    margin-bottom: 30px;
  }

  .NoResultsFound {
    margin-bottom: 50px;
  }
}
