@import "assets/scss/vars.scss";

.SocialMedia .ShareButton {
  cursor: pointer;
  font-size: 22px;
  color: black;
  line-height: 25px;
  #{$rtl} & {
    transform: rotate(180deg);
  }
}
.SocialMedia :global(.dropdown-toggle::after) {
  content: none !important;
}

.SocialMedia :global(.dropdown) {
  display: flex;
  align-items: center;
}

// New Page Slider Share Icon
:global(.shareIco) :global(.dropdown) {
  #{$rtl} & {
    justify-content: flex-end;
    direction: ltr;
  }
}

:global(.shareIco) .ShareButton {
  #{$rtl} & {
    order: 1;
  }
}

:global(.shareIco) :global(.dropdown-menu) {
  #{$rtl} & {
    direction: rtl;
  }
}
// New Page Slider Share Icon

.SocialMedia :global(.dropdown-menu) {
  display: flex !important;
  align-items: center !important;
  transform: none !important;
  position: relative !important;
  border-radius: 0 !important;
  border: none;
  margin: 0 !important;
  padding: 0 !important;
  animation: close 0.3s 1 forwards linear;
  visibility: hidden;
  opacity: 0;
  max-width: 0 !important;
  min-width: auto;
  transition: all 0.5s;

  #{$ltr} & {
    margin-left: 20px;
  }
  #{$rtl} & {
    margin-right: 20px;
  }
  background-color: transparent !important;
}

.SocialMedia :global(.dropdown-menu.show) {
  visibility: visible;
  opacity: 1;
  max-width: 160px !important;
  animation: open 0.3s 1 forwards linear;
  min-width: auto;
  #{$ltr} & {
    margin-left: 20px !important;
  }
  #{$rtl} & {
    margin-right: 20px !important;
  }
}
@keyframes open {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 160px;
  }
}
@keyframes close {
  0% {
    max-width: 160px;
  }
  100% {
    max-width: 0;
  }
}

.SocialMedia :global(.dropdown-item) {
  display: inline;
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
  padding: 0 !important;
  outline: 0 none;
  font-size: 0;
}

.SocialMedia :global(.dropdown-item):active {
  background-color: transparent;
}

.SocialMedia :global(.dropdown-item:last-child) {
  #{$ltr} & {
    margin-right: 0 !important;
  }
  #{$rtl} & {
    margin-left: 0 !important;
  }
}

.SocialMedia :global(.dropdown-item:hover) {
  color: #e22523 !important;
}

.SocialMedia .Icon {
  color: black !important;
  font-size: 22px;
  transition: all 0.3s;
}

.SocialMedia .Icon:hover {
  color: #e22523 !important;
}

@media only screen and (max-width: 768px) {
  .SocialMedia {
    position: absolute;
    #{$ltr} & {
      right: 40px;
    }
    #{$rtl} & {
      left: 40px;
    }
    // background-color: white;
  }
}
