@import "assets/scss/vars.scss";

.RejectCasesTable {
}

.PersonalTable {
  overflow-x: hidden;
}

.TitleTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Tabs {
  display: flex;
}

.Tabs span {
  #{$ltr} & {
    margin-right: 38px;
  }
  #{$rtl} & {
    margin-left: 38px;
  }
  cursor: pointer;
}

.Tabs span:last-child {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$ltr} & {
    margin-left: 0;
  }
}

:global(.table) {
  width: 825px !important;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.Table thead th {
  border-bottom: 0;
  border-top: 0;
  vertical-align: baseline;
  padding: 20px;
  background-color: #fcfcfc;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-weight: normal;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 825px;
}

.Table thead th:first-child {
  #{$ltr} & {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #{$rtl} & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  white-space: nowrap;
  max-width: 80px;
}

.Table thead th:last-child {
  #{$ltr} & {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  #{$rtl} & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.Table thead th div {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  cursor: pointer;
}

.Table thead th div i {
  font-size: 8px;
  color: #00158b;
  font-weight: bold;
  #{$ltr} & {
    margin-left: 10px;
  }
  #{$rtl} & {
    margin-right: 10px;
  }
  transition: all 0.3s;
}

.Table thead th div.Active i {
  transform: rotate(180deg);
}

.Table tbody td {
  border-top: 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 20px;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-weight: normal;
}

.Table tbody td:last-child {
  #{$ltr} & {
    padding-right: 0;
  }
  #{$rtl} & {
    padding-left: 0;
  }
}

.Table tbody td:first-child {
  max-width: 80px;
}

.Table tbody td.NoAvailableData {
  text-align: center;
  border-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  font-family: "Lato";
  text-transform: uppercase;
}

.ArrowLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #00158b;
}

.ArrowLink a {
  #{$ltr} & {
    transform: rotate(270deg);
  }
  #{$rtl} & {
    transform: rotate(90deg);
  }
}

.ArrowLink a i {
  font-size: 8px;
  color: #00158b;
  font-weight: bold;
}

.ArrowColumn {
  display: flex;
  justify-content: space-between;
}

.SelectCheckbox {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  position: relative;
}

.SelectCheckbox :global(.DropMenu) {
  border-radius: 22px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  padding: 30px;
  width: 350px;
  white-space: nowrap;
  #{$rtl} & {
    text-align: right;
  }
}

// status dot
.Table tbody td div.Status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
}

.Dot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  #{$ltr} & {
    margin-right: 5px;
  }
  #{$rtl} & {
    margin-left: 5px;
  }
}

:global(.new) {
  color: #ffbe0d;
}

:global(.new) .Dot {
  background-color: #ffbe0d;
}

:global(.approved) {
  color: #1ce1ac;
}

:global(.approved) .Dot {
  background-color: #1ce1ac;
}

:global(.inprogress) {
  color: #1457e9;
}

:global(.inprogress) .Dot {
  background-color: #1457e9;
}

:global(.rejected) {
  color: #e22523;
}

:global(.rejected) .Dot {
  background-color: #e22523;
}

:global(.withdrawal) {
  color: #ff00ff;
}

:global(.withdrawal) .Dot {
  background-color: #ff00ff;
}

:global(.initialaccepted) {
  color: #ff1493;
}

:global(.initialaccepted) .Dot {
  background-color: #ff1493;
}

@media (max-width: 768px) {
  .PersonalTable {
    overflow-x: auto;
  }

  .TitleTabs {
    display: block;
  }

  .TitleTabs .Title {
    margin-bottom: 30px;
  }

  .Tabs {
    white-space: nowrap;
    overflow-x: auto;
  }

  .SelectCheckbox :global(.DropMenu) {
    width: 250px;
  }
}
