@import "assets/scss/vars.scss";

.Personal {
}

:global(.react-select-container):focus {
  outline: 0 none;
  box-shadow: none;
}

:global(.react-select-container .react-select__control) {
  height: 43px;
  padding: 0 14px;
  border-radius: 50px;
  background-color: transparent;
  // color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(112, 112, 112, 0.2);
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  outline: none;
  cursor: pointer;
}

:global(.react-select-container .react-select__control):hover {
  border-color: rgba(112, 112, 112, 0.2);
}

:global(.react-select-container
    .react-select__control.react-select__control--is-focused) {
  box-shadow: none;
}

:global(.react-select-container
    .react-select__control.react-select__control--is-focused):hover {
  border-color: rgba(112, 112, 112, 0.2);
}

:global(.react-select-container
    .react-select__control.react-select__control--menu-is-open) {
  border-color: rgba(112, 112, 112, 0.2);
}

:global(.react-select-container .react-select__value-container) {
  padding: 0;
}

:global(.react-select-container
    .react-select__value-container
    .react-select__single-value) {
  text-transform: capitalize;
}

:global(.react-select-container
    .react-select__value-container
    .react-select__placeholder) {
  margin-left: 0;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.5);
}

:global(.react-select-container
    .react-select__indicators
    .react-select__indicator-separator) {
  display: none;
}

:global(.react-select-container .react-select__menu) {
  min-width: 100%;
  border-radius: 25px;
  border: 1px solid hsla(0, 0%, 43.9%, 0.2);
  padding: 10px 30px;
  box-shadow: none;
}

:global(.react-select-container .react-select__menu .react-select__menu-list) {
  padding: 0;
}

:global(.react-select-container
    .react-select__menu
    .react-select__menu-list
    .react-select__option) {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid hsla(0, 0%, 43.9%, 0.2);
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  line-height: 17px;
  background-color: transparent;
  font-size: 12px;
  line-height: 17px;
  color: #000 !important;
  font-family: Lato;
  text-transform: capitalize;
}

:global(.react-select-container .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-disabled){
  color: #b3b3b3 !important;
  text-decoration: line-through;
}

:global(.react-select-container
    .react-select__menu
    .react-select__menu-list
    .react-select__option):last-child {
  border-bottom: 0;
}

:global(.react-select-container
    .react-select__control
    .react-select__indicators
    .react-select__indicator) {
  padding: 0;
  color: #000;
  font-size: 10px;
}

:global(.react-select-container
    .react-select__control
    .react-select__indicators
    .react-select__indicator) {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

:global(.react-select-container
    .react-select__control.react-select__control--menu-is-open
    .react-select__indicators
    .react-select__indicator) {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.disabledSignUpBtn {
  cursor: no-drop !important;
}
