@import "assets/scss/vars.scss";

.NewsPageCards {
  padding: 50px 0 70px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .NewsPageCards {
    padding: 30px 0 0;
  }
}
