@import 'assets/scss/vars.scss';

.AddPadding {
    padding: 100px 0 70px;
}

@media only screen and (max-width: 768px) {
    .AddPadding {
        padding: 30px 0 0;
    }
}