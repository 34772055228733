@import "assets/scss/vars.scss";

.EditPersonalProfile {
}

.EditForm .ChangePass {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #000000;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f00;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
  }
  &:hover::after {
    visibility: visible;
    background-color: #e22523;
    transform: scaleX(1);
  }
}

.UpdatedSuccessfully {
  color: #f00;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .EditForm :global(.LogBtns) {
    flex-direction: row;
    :global(.Button):first-child {
      #{$ltr} & {
        margin-right: 15px;
      }
      #{$rtl} & {
        margin-left: 15px;
      }
    }
    :global(.Button):last-child {
      #{$ltr} & {
        margin-left: 15px;
      }
      #{$rtl} & {
        margin-right: 15px;
      }
    }
  }
}

// DatePicker
.DatePicker {
  position: relative;
}

.DatePicker i {
  font-size: 21px;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  color: #002b69;
  pointer-events: none;
}
