@import "assets/scss/vars.scss";

.Proces {
  overflow: hidden;
}
.Proces .SubTitle {
  color: #00158b;
}

.Proces :global(.owl-stage-outer) {
  overflow: visible;
}
.Proces :global(.owl-item) {
  opacity: 0.2;
  transition: 0.3s;
}
.Proces :global(.owl-item:first-child),
.Proces :global(.owl-item:last-child) {
  opacity: 0.2;
}
.Proces :global(.owl-item.first-active) {
  opacity: 1;
}
:global(.owl-carousel.owl-drag .owl-item) {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
// Owl-Nav
.Proces :global(.owl-theme .owl-nav) {
  position: absolute;
  #{$ltr} & {
    right: 0;
  }
  #{$rtl} & {
    left: 0;
  }
  top: -70px;
  font-size: 31px;
  margin: 0;
  height: 20px;
  line-height: 20px;
}
.Proces :global(.owl-theme .owl-nav button.owl-prev),
.Proces :global(.owl-theme .owl-nav button.owl-next) {
  width: auto;
  padding: 0 !important;
  margin: 0;
  height: 20px;
  background-color: transparent;
  color: #000000;
  transition: 0.3s;
}
.Proces :global(.owl-theme .owl-nav button.owl-prev:hover),
.Proces :global(.owl-theme .owl-nav button.owl-next:hover) {
  color: #00158b;
}
.Proces :global(.owl-theme .owl-nav button.owl-prev.disabled),
.Proces :global(.owl-theme .owl-nav button.owl-next.disabled) {
  opacity: 0;
  visibility: hidden;
}
.Proces :global(.owl-theme .owl-nav button.owl-prev i),
.Proces :global(.owl-theme .owl-nav button.owl-next i) {
  line-height: 20px;
  display: block;
}
.Proces :global(.owl-theme .owl-nav button.owl-next) {
  margin-left: 30px;
}

.ProcessBox .Img {
  height: 38px;
  margin-bottom: 10px;
}

.ProcessBox .Img img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  object-fit: cover;
}

.ProcessBox a {
  display: block;
}

.ProcessBox .Title {
  margin-bottom: 15px;
  min-height: 58px;
}

.ProcessBox .Bullet {
  display: block;
  height: 8px;
  width: 8px;
  background-color: #e22523;
  margin-bottom: 15px;
  border-radius: 100%;
}

.ProcessBox .SubTitle {
  height: 31px;
  overflow: hidden;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
}

.ProcessBox .Desc {
  height: 51px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .ProcessBox .SubTitle {
    height: 58px;
    white-space: pre-wrap;
    margin-bottom: 10px;
  }

  .Proces :global(.owl-theme .owl-nav) {
    position: static;
    margin-top: 40px;
    text-align: center;
    // #{$ltr} & {
    //   text-align: right;
    //   padding-right: 30px;
    // }
    // #{$rtl} & {
    //   text-align: left;
    //   padding-left: 30px;
    // }
  }

  .Proces :global(.col-11) {
    padding-right: 0;
  }
}
