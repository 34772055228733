@import "assets/scss/vars.scss";

.LocalizationDropDown {
  font-size: 0;
}

.LocalizationDropDown:global(.dropdown) {
  :global {
    .dropdown-menu {
      text-align: center;
      padding: 11px 30px;
      width: 255px;
      border-radius: 25px;
      // top: 50px !important;
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      // transition-property: opacity, visibility, top;
      box-shadow: 0px 0px 100px -15px rgba(0, 14, 92, 0.15);
      padding-bottom: 35px;
      #{$ltr} & {
        // right: 0 !important;
        // left: auto !important;
      }
      #{$rtl} & {
        left: 0 !important;
        right: auto !important;
      }
    }
    .dropdown-menu.show {
      top: 10px !important;
      opacity: 1;
      visibility: visible;
    }
    .dropdown-menu.show .SignLink {
      // border-bottom-color: transparent;
    }
  }
  .OtherB {
    #{$ltr} & {
      text-align: left;
    }
    #{$rtl} & {
      text-align: right;
    }
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .flag {
    width: 19px;
    #{$ltr} & {
      margin-right: 10px;
    }
    #{$rtl} & {
      margin-left: 10px;
    }
  }
}
.LocalizationDropDown {
  :global {
    .SignLink {
      display: block;
      padding: 10px 0;
      // border-bottom: 1px solid #ededed;
      cursor: pointer;
      #{$ltr} & {
        text-align: left !important;
      }
      #{$rtl} & {
        text-align: right !important;
      }

      &:last-child {
        // border-bottom: 0;
      }
    }
  }
}

.LocalizationDropDown {
  .LocalizationDropDown {
    :global {
      .dropdown-menu {
        padding-bottom: 11px;
        width: 195px;
        top: -5px !important;
        box-shadow: 0px 0px 100px 15px rgba(0, 14, 92, 0.15);
        .SignLink {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #ededed !important;
          &:after {
            display: none;
          }
          &:last-child {
            border-bottom: 0 !important;
          }
        }
      }
      .dropdown-menu.show {
        .SignLink {
          // border-bottom-color: transparent;
        }
      }
      .SignLink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:after {
          content: "\e911";
          display: block;
          font-family: "icomoon" !important;
          font-size: 9px;
        }
        &:last-child {
          border-bottom: 1px solid #ededed;
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.LangIcon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-image: url("/img/lang-icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.LangIcon:hover {
  background-image: url("/img/lang-icon-hover.svg");
}

.LanguageIcon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  transition: 0.3s;
}

.LanguageIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.LanguageIcon img:last-child {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.LanguageIcon:hover img:first-child {
  visibility: hidden;
  opacity: 0;
}

.LanguageIcon:hover img:last-child {
  visibility: visible;
  opacity: 1;
}
