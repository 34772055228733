@import "assets/scss/vars.scss";

.OurTeamSlide {
  :global {
    .owl-carousel .owl-stage-outer {
      overflow: visible;
      // #{$rtl} & {
      //   direction: rtl;
      // }
    }

   .owl-carousel .owl-stage-outer .owl-stage{
      #{$rtl} & {
        margin-left: auto;
      }
    }

    .owl-carousel.owl-drag .owl-item {
      opacity: 0.1;
      //  #{$rtl} & {
      //   float: right;
      // }
    }

    .owl-carousel.owl-drag .owl-item.active {
      opacity: 1;
    }

    .owl-carousel .owl-nav button {
      outline: none;
      border: 0;
      margin: 0;
      color: #000;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      &:hover {
        color: #00158b;
      }
    }

    .owl-carousel .owl-nav button i {
      font-size: 31px;
      display: block;
      line-height: 12px;
    }

    .owl-theme .owl-nav {
      position: absolute;
      top: -60px;
      line-height: 12px;
      height: 12px;
      #{$ltr} & {
        right: 0;
      }
      #{$rtl} & {
        left: 0;
      }
      margin-top: 0;
    }

    .owl-carousel .owl-nav button:hover {
      background-color: transparent;
    }

    .owl-carousel .owl-nav button.owl-next img,
    .owl-carousel .owl-nav button.owl-prev img {
      width: 31px;
    }

    .owl-carousel .owl-nav button.owl-prev {
      margin-right: 15px;
    }

    .owl-carousel .owl-nav button.owl-next {
      margin-left: 15px;
    }
  }
}

.OurTeamSlider {
  padding: 100px 0;
  overflow: hidden;
  :global {
    .TitleFont {
      margin-bottom: 50px;
      text-transform: capitalize;
    }
  }
}

.OurTeamSlider .TitleFont {
  color: #000;
}

.OurTeam .Img {
  width: 100%;
  border-radius: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 240px;
}

.OurTeam .Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.OurTeam .Tit {
  margin-bottom: 10px;
}

.OurTeam .Desc {
  color: #e22523;
}

@media (max-width: 768px) {
  .OurTeamSlider {
    padding: 30px 0;
    :global {
      .TitleFont {
        margin-bottom: 30px;
      }
    }
  }

  .OurTeamSlide {
    width: 255px;
    :global {
      .owl-theme .owl-nav {
        position: static;
        margin-top: 30px;
        #{$ltr} & {
          text-align: right;
          padding-right: 30px;
        }
        #{$rtl} & {
          text-align: left;
          padding-left: 30px;
        }
      }
    }
  }
}
