@import "assets/scss/vars.scss";

.NewsPageOuter {
	position: relative;
	transition: all 0.3s;
	border-radius: 30px;
	padding-bottom: 25px;
	margin-bottom: 30px;
}

.NewsPage {
	padding: 40px 27px 55px 40px;
	border-radius: 30px;
	background-color: white;
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.04);
	overflow: hidden;
	transition: all 0.3s;
}

.NewsPageOuter:hover .NewsPage {
	box-shadow: 0px 0px 100px -15px rgba(0, 14, 92, 0.15);
}

.NewsPage .NewsHead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.NewsPage .NewsHead .Date {
	color: rgba(0, 0, 0, 0.5);
}

.Img {
	width: 100%;
	height: 98px;
	border: 1px solid #ededed;
	margin-bottom: 17px;
}
.NewsPage .Img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.NewsPage p {
	font-family: latoBl !important;
	max-height: 89px;
	line-height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-word;
	min-height: 48px;
}

.NewsPageOuter .Button {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.NewsPage :global(.Other) {
	// word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-height: 45px;
	// overflow: hidden;
}

.NewsPage :global(.Other) p {
	max-height: max-content !important;
	min-height: auto !important;
}

.NewsPage :global(.Other) ul {
	padding: initial;
	margin: auto;
	list-style: initial;
}

@media only screen and (max-width: 768px) {
	.NewsPageOuter {
		:global {
			.SocialShareIcon {
				#{$rtl} & {
					position: relative;
					right: 0;
				}
			}
		}
	}

	.NewsPage .NewsHead .Date {
		flex-shrink: 0;
	}

	.NewsPage .SocialMedia {
		position: absolute;
		right: 40px;
		background-color: white;
	}
}
