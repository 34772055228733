@import "assets/scss/vars.scss";

.Services {
  background-color: #fcfcfc;
}

.ServiceBox {
  // margin-top: 50px;
  padding: 40px;
  border-radius: 20px;
  transition: all 0.3s;
  background-color: white;
  // margin-top: 15px;
  // margin-bottom: 15px;
}

.ServiceBox:hover {
  box-shadow: 0px 0px 65px 5px rgba(0, 14, 92, 0.15);
}

.ServiceBox .ServiceHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ServiceBox .ServiceHead .Img {
  height: 59px;
}

.ServiceBox .Tit {
  margin-bottom: 30px;
}

.ServiceBox ul {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 15px;
}

.ServiceBox ul li::before {
  content: "\2022";
  color: #e22523;
  font-size: 10px;
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
}

.ServiceBox ul li {
  display: flex;
  align-items: center;
}

.ServiceBox .Button {
  background-color: #ffffff;
  color: #e22523;
}

.ServiceBox .Button:hover {
  background-color: #e22523;
  color: white;
}

@media only screen and (max-width: 768px) {
  .Services .ServiceBox {
    margin-top: 30px;
  }

  .Services :global(.col-12:first-child) .ServiceBox {
    margin-top: 0;
  }

  .ServiceBox ul {
    grid-template-columns: auto;
  }
}
