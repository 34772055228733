@import "assets/scss/vars.scss";

.FilterBar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00158b+0,000b46+100 */
  background: rgb(0, 21, 139);
  /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(0, 21, 139, 1) 0%,
    rgba(0, 11, 70, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 21, 139, 1) 0%,
    rgba(0, 11, 70, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 21, 139, 1) 0%,
    rgba(0, 11, 70, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00158b', endColorstr='#000b46', GradientType=1);
  /* IE6-9 */
}

.FilterList {
  display: flex;
  align-items: center;
}

.FilterList li a {
  color: white;
  #{$ltr} & {
    margin-right: 52px;
  }
  #{$rtl} & {
    margin-left: 52px;
  }
}

.FilterList li:last-child a {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$rtl} & {
    margin-left: 0;
  }
}

.FilterList li a:hover {
  color: #e22523;
}

a.Active {
  color: #e22523 !important;
}

.active {
  color: #e22523 !important;
}

@media only screen and (max-width: 768px) {
  .FilterList {
    justify-content: center;
  }
}
