@import "assets/scss/vars.scss";

.BadRequestMessage {
}

.errorMsg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DescFont {
  color: #e22523 !important;
}

.backLink {
  text-transform: uppercase;
  text-decoration: underline !important;
}
