@import "assets/scss/vars.scss";

.Header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #030961;
  z-index: 999;
}
.Header.Sticky {
  position: sticky;
}
.headerBackHeight {
  height: 95px;
}
.Header.WithBack::before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);
  top: 0;
  left: 0;
  background-image: url(/img/background-Header.png);
}
.Header.WithBack::before {
  /*            display: none;*/
}

.Nav,
.LeftSide,
.RightSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LeftSide .Logo {
  width: 94px;
  height: 90px;
  z-index: 99;
  position: relative;
  display: block;
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
}
.LeftSide .Logo img {
  width: 100%;
}

.RightSide .NavItem {
  color: white;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  display: inline-block;
  // transition: all 0.2s;
}

.RightSide .NavItem:hover {
  color: #e22523;
}

.RightSide .NavItem:first-child::after {
  display: none;
}

.RightSide .DropBtn::after,
.RightSide .NavItem::after {
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1.5px;
  #{$ltr} & {
    left: -20px;
  }
  #{$rtl} & {
    right: -20px;
  }
}
.Header .RightSide .DropBtn::after {
  #{$ltr} & {
    left: 0;
  }
  #{$rtl} & {
    right: 0;
  }
}

.User {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  #{$ltr} & {
    margin-left: 5px;
  }
  #{$rtl} & {
    margin-right: 5px;
  }
}
.User img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Bell {
  position: relative;
}
.Count {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 8px;
  background-color: #e22523;
  color: #ffffff;
  border: 1px solid #030961;
  border-radius: 25px;
  padding: 1px 2px;
  transform: translate(50%, -50%);
}
.ResponsiveHeader {
  position: sticky;
  top: 0;
  background-color: #030961;
  z-index: 999;
}
.ResponsiveHeader > :global(.container) {
  position: relative;
  z-index: 999;
}
.ResponsiveHeader .Inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ResponsiveHeader .NavItem {
  font-size: 19px;
}
.NavToggler {
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  cursor: pointer;
  #{$ltr} & {
    margin-left: 15px;
  }
  #{$rtl} & {
    margin-right: 15px;
  }
  span {
    position: absolute;
    display: block;
    width: 30px;
    height: 1px;
    background-color: #ffffff;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    transform-origin: center;
    &:first-child {
      top: 0;
    }
    &:last-child {
      top: calc(100% - 1px);
    }
  }
}
.NavToggler.active {
  span {
    &:first-child {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:last-child {
      top: 50%;
      transform: translate(-50%, -50%) rotate(-225deg);
    }
  }
}
.Responsive {
  position: fixed;
  display: flex;
  top: 90px;
  left: 0;
  height: calc(100% - 90px);
  background-color: #030961;
  width: 100%;
  max-width: 100%;
  transform: translateX(-100%);
  transition: 0.3s ease-in;
  padding-bottom: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  overflow: auto;
  z-index: 999;
}
.Responsive.open {
  transform: none;
  transition: 0.3s ease-out;
}
.UserArea {
  padding: 15px 0;
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -15px;
    height: 1px;
    width: calc(100% + 30px);
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.UserArea .User {
  #{$ltr} & {
    margin-right: 25px;
  }
  #{$rtl} & {
    margin-left: 25px;
  }
}
.UserArea .UserName {
  font-size: 24px;
  color: #ffffff;
}

.searchIcon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-image: url("/img/search-icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.searchIcon:hover {
  background-image: url("/img/search-icon-hover.svg");
}

.SearchIcon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.SearchIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SearchIcon img:last-child {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

.SearchIcon:hover img:first-child {
  visibility: hidden;
  opacity: 0;
}

.SearchIcon:hover img:last-child {
  visibility: visible;
  opacity: 1;
}

.ResponsiveHeader .SearchIcon {
  width: 19px;
  height: 19px;
}

@media (max-width: 768px) {
  .UserArea::before {
    width: 200%;
    left: -50%;
  }
  .RightSide .DropBtn::after,
  .RightSide .NavItem::after {
    #{$ltr} & {
      left: 0 !important;
    }
    #{$rtl} & {
      right: 0 !important;
    }
  }

  .ResponsiveHeader .NavItem {
    font-size: 10px;
  }
}
@media (max-width: 375px) {
  .UserArea::before {
    width: calc(100% + 30px);
    left: -15px;
  }
}
@media (max-width: 280.98px) {
  .RightSide .NavItem {
    margin-left: 10px;
    margin-right: 10px;
  }
}
