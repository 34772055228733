@import "assets/scss/vars.scss";

.Header {
	padding-top: 30px;
	position: relative;
	z-index: 1;
	background-color: #030961;
	// padding-bottom: 25px;
}

.Header::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	height: 155px;
	z-index: -1;
}

.HeaderBg {
	position: absolute;
	// background-image: url(/img/background-header.png);
	// height: 483px;
	width: 100%;
	top: 0;
	background-size: cover;
}

.Header .titleFont {
	color: #ffffff;
	margin-bottom: 10px;
	text-transform: capitalize;
}

.Header .Tit {
	color: #e22523;
	font-family: latoBl;
	font-size: 48px;
	line-height: 57px;
	margin-bottom: 10px;
}

.Header .Desc {
	color: white;
	margin-bottom: 30px;
	// max-height: 255px;
	// overflow: hidden;
}

.AboutUsVideo {
	width: 100%;
	height: 318px;
	position: relative;
}

.AboutUsVideo .Img {
	width: 100%;
	height: 100%;
	border-radius: 30px;
	overflow: hidden;
}

.AboutUsVideo .Img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 30px;
}

.AboutUsVideo .Play {
	position: absolute;
	bottom: 0;
	transform: translateY(50%);
	#{$ltr} & {
		left: 95px;
	}
	#{$rtl} & {
		right: 95px;
	}
}

.AboutUsVideo .Play {
	:global {
		.icon-play {
			#{$ltr} & {
				margin-right: 6px;
			}
			#{$rtl} & {
				margin-left: 6px;
				transform: rotate(180deg);
			}
			font-size: 11px;
		}
	}
}

// .AboutUsVideo button.Play {
//   display: none;
// }

// pass it in additional class in About Page [PagesHead] Component because it has margin-bottom: 30px but in other pages like our services or our client title has margin-bottom: 10px
:global(.TitleFontM) .titleFont {
	margin-bottom: 30px;
}

:global(.HeadWithImg) {
	padding-top: 100px;
}

:global(.MarginTop) {
	padding-top: 100px;
}
:global(.VideoModal.modal) {
	display: block;
	opacity: 0;
	transform: scale(0.7);
	transition: all 0.3s ease-in;
}

:global(.VideoModal.modal .CloseIcon) {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background-color: transparent;
	color: #000;
	padding: 0 1rem;
	font-size: 35px;
}

:global(.VideoModal.modal.show) {
	transform: scale(1);
	opacity: 1;
	transition: all 0.3s ease-in;
	transition-delay: 0.1s;
}

:global(.VideoModal .modal-content) {
	width: 920px;
	margin: auto;
	border: none;
	border-radius: 0;
	background-color: transparent;
	margin-top: 100px;
}

:global(.VideoModal) iframe {
	width: 100%;
	height: 517px;
	border: 0;
}

// Applied Service
.disabledServices {
	cursor: no-drop !important;
}

.disabledServices:hover {
	opacity: 0.5;
	color: #000;
}

@media only screen and (max-width: 768px) {
	.Header::after {
		height: 55px;
	}

	// pass it in additional class in About Page [PagesHead] Component because it has margin-bottom: 20px [in Responsive] but in other pages like our services or our client title has margin-bottom: 10px
	:global(.TitleFontM) .titleFont {
		margin-bottom: 20px;
	}

	.Header .Desc {
		// max-height: 225px; // Remove max height to let whole paragraph appear
		overflow: hidden;
	}

	.Header .Tit {
		font-size: 30px;
		line-height: 36px;
	}

	.AboutUsVideo {
		height: 175px;
	}

	.AboutUsVideo .Play {
		#{$ltr} & {
			left: 50%;
			transform: translate(-50%, 50%);
		}
		#{$rtl} & {
			right: 50%;
			transform: translate(50%, 50%);
		}
	}

	:global(.ourServicesHeader) {
		padding-top: 30px;
	}

	:global(.MarginTop) {
		padding-top: 30px;
	}
	:global(.VideoModal .modal-content) {
		width: 90%;
	}
	:global(.VideoModal) iframe {
		height: 300px;
	}
}
