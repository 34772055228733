@import "assets/scss/vars.scss";

.SignIn {
}
.RememberRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .ErrMsg {
    #{$ltr} & {
      position: absolute !important;
    }
  }
}
