@import "assets/scss/vars.scss";

.ServiceInfo {
  width: 100%;
  padding: 20px;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-bottom: 30px;
}

.ServiceInfo .Tit {
  font-family: latoB;
  margin-bottom: 15px;
}

.ServiceInfo .Desc {
  color: #e22523;
}

@media only screen and (max-width: 768px) {
  .ServiceInfo {
    text-align: center;
  }
}
