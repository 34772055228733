@import "assets/scss/vars.scss";

.Profile {
    padding: 100px 0;
}

@media (max-width: 768px) {
    .Profile {
        padding: 30px 0 50px;
    }
}
