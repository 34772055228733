@import "assets/scss/vars.scss";

.ContactForm .BtnsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ContactForm .DirectMsg {
  margin-bottom: 20px;
}

:global(.NotRobot .CheckLabel) {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$rtl} & {
    margin-left: 0;
  }
}

:global(label.Required.NotRobot)::after {
  margin-top: -5px;
}

@media (max-width: 768px) {
  .ContactForm .DirectMsg {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .ContactForm .BtnsRow {
    position: relative;
  }

  .ContactForm .BtnsRow :global(.err) {
    position: absolute;
    #{$ltr} & {
      left: 0;
    }
    #{$rtl} & {
      right: 0;
    }

    top: 30px;
  }
}

@media (max-width: 320px) {
  .ContactForm .BtnsRow :global(.err) {
    max-width: 130px;
  }

  .ContactForm .BtnsRow :global(.NotRobot .CheckLabel) {
    #{$ltr} & {
      max-width: 65px;
      width: 100%;
    }
  }

  :global(label.Required.NotRobot)::after {
    #{$ltr} & {
      margin-top: -26px;
      margin-left: -8px;
    }
  }
}

@media (max-width: 280px) {
  :global(.NotRobot .CheckLabel) {
    #{$rtl} & {
      width: 100%;
      max-width: 60px;
    }
  }

  :global(label.Required.NotRobot)::after {
    #{$rtl} & {
      margin-top: -20px;
      margin-right: -18px;
    }
  }
}
