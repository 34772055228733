@import "assets/scss/vars.scss";

footer {
	background-color: #000f60;
	padding-bottom: 20px;
}

footer .FooterHolder {
	margin-bottom: 50px;
}

footer .Logo {
	width: 94px;
	margin-bottom: 56px;
}

footer a {
	display: block;
}

footer .FooterSection p,
footer .FooterSection a {
	color: white;
	margin-bottom: 20px;
}

footer .FooterSection a:last-child {
	#{$ltr} & {
		margin-right: 0;
	}
	#{$rtl} & {
		margin-left: 0;
	}
	margin-bottom: 0;
}

footer .FooterIcon {
	#{$ltr} & {
		padding-left: 30px;
	}
	#{$rtl} & {
		padding-right: 30px;
	}
}

footer .FooterIcon a {
	display: inline-block;
	#{$ltr} & {
		margin-right: 20px;
	}
	#{$rtl} & {
		margin-left: 20px;
	}
	font-size: 22px;
	margin-bottom: 0;
}

footer :global(.subscribe-form) {
	position: relative;
	border: 1px solid #fff;
	border-radius: 25px;
	overflow: hidden;
	background-color: #ffffff;
}

footer input {
	width: 100%;
	border: 0;
	padding: 0 20px;
	border-radius: 50px;
	height: 50px;
	outline: none;
}

footer .BtnSubmit {
	position: absolute;
	#{$ltr} & {
		right: 0;
	}
	#{$rtl} & {
		left: 0;
	}
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent;
	cursor: pointer;
	width: 83px;
	height: 100%;
	padding: 0;
	transition: 0.3s ease-out;
}
footer input:focus + .BtnSubmit,
footer .BtnSubmit:hover {
	color: #ffffff;
	background-color: #000f60;
}

footer .BtnSubmit:before {
	content: "";
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 17px;
	width: 1px;
	#{$ltr} & {
		left: 0;
	}
	#{$rtl} & {
		right: 0;
	}
}

footer .BtnSubmit .NextIcon {
	color: #e22523;
	font-size: 31px;
	line-height: 20px;
	display: block;
	#{$rtl} & {
		transform: rotate(180deg);
		// display: inline-block;
	}
	&::before {
		line-height: 20px;
	}
}

footer .Separator {
	width: 100%;
	height: 1px;
	background-color: rgba(255, 255, 255, 0.2);
	margin-bottom: 20px;
}

footer .LastSection {
	display: flex;
	justify-content: space-between;
}

footer .LastSection p,
footer .LastSection a {
	color: white;
}

footer .TermsPrivacy {
	display: flex;
	align-items: center;
}

footer .TermsPrivacy a:first-child {
	#{$ltr} & {
		margin-right: 25px;
	}
	#{$rtl} & {
		margin-left: 25px;
	}
}

footer .TermsPrivacy span {
	color: rgba(255, 255, 255, 0.2);
	font-size: 15px;
	#{$ltr} & {
		margin-right: 25px;
	}
	#{$rtl} & {
		margin-left: 25px;
	}
}

footer a:hover {
	color: #e22523;
}

footer .CopyRights {
	direction: ltr;
}

@media only screen and (max-width: 768px) {
	footer .FooterHolder {
		margin-bottom: 0;
	}

	footer .FooterHolder .FooterSection {
		margin-bottom: 30px;
	}

	footer .LastSection {
		flex-direction: column;
	}

	footer .LastSection p {
		margin-bottom: 30px;
	}
}
