@import "assets/scss/vars.scss";

.NewsPageHeader {
  position: relative;
  padding-top: 100px;
  padding-bottom: 30px;
  overflow: hidden;
  :global {
    .owl-theme .owl-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      #{$ltr} & {
        right: 95px;
      }
      #{$rtl} & {
        left: 95px;
      }
      top: calc(318px - 30px);
      margin-top: 0;
    }

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e22523;
      border-radius: 100%;
      color: white;
      margin: 0;
      transition: all 0.3s;
    }

    .owl-carousel .owl-nav button.owl-prev {
      margin-right: 5px;
      box-shadow: 0 10px 16px rgba(226, 37, 35, 0.4);
    }

    .owl-carousel .owl-nav button.owl-next {
      margin-left: 5px;
      box-shadow: 0 10px 16px rgba(226, 37, 35, 0.4);
    }

    .owl-carousel .owl-nav button.owl-prev:hover,
    .owl-carousel .owl-nav button.owl-next:hover {
      transform: scale(1.1);
    }

    .owl-carousel .owl-nav button.owl-prev:focus,
    .owl-carousel .owl-nav button.owl-next:focus {
      outline: 0 none;
    }

    .owl-carousel .owl-nav button.owl-prev img,
    .owl-carousel .owl-nav button.owl-next img {
      width: 11px;
    }

    .owl-carousel .owl-nav button.owl-prev img {
      transform: rotate(180deg);
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fcfcfc;
  }
}

.NewsPageBg {
  position: absolute;
  background-image: url(/img/background-header.png);
  background-color: #030961;
  width: 100%;
  height: 290px;
  top: 0;
  background-size: cover;
}

.NewsPageHeader .Img {
  width: 100%;
  height: 318px;
  border-radius: 30px;
  overflow: hidden;
}

.NewsPageHeader .Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NewsPageMainImg {
  position: relative;
  margin-bottom: 55px;
}

.NewsPageMainImg .NewsPageButtons {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  width: 100%;
  display: flex;
  #{$ltr} & {
    justify-content: space-between;
  }
  #{$rtl} & {
    justify-content: flex-start;
  }
  align-items: center;
  padding: 0 95px;
}

.NewsPageMainImg .NewsPageArrow {
  display: inline-block;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e22523;
  border-radius: 100%;
  color: white;
}

.NewsPageMainImg .NewsPageArrow:hover {
  transform: scale(1.1);
}

.NewsPageMainImg .NewsPageArrow i {
  transform: rotate(270deg);
  font-size: 11px;
}

.NewsPageContent .Date {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.NewsPageContent .Tit {
  margin-bottom: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.NewsPageContent .Desc {
  margin-bottom: 20px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.NewsPageContent .Desc ul {
  padding: initial;
  margin: auto;
  list-style: initial;
}

@media only screen and (max-width: 768px) {
  .NewsPageHeader {
    padding-top: 30px;
    :global {
      .owl-theme .owl-nav {
        #{$ltr} & {
          right: 30px;
        }
        #{$rtl} & {
          left: 30px;
        }
        top: calc(175px - 30px);
      }
    }
  }
  .NewsPageHeader :global(.HeaderShareIcon) {
    position: relative;
    left: 0 !important;
  }

  .NewsPageHeader .Desc {
    margin-bottom: 20px;
  }

  .NewsPageBg {
    height: 118px;
  }

  .NewsPageHeader .Img {
    height: 175px;
  }

  .NewsPageMainImg .NewsPageButtons {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 375px) {
  .NewsPageHeader {
    :global {
      .owl-theme .owl-nav {
        top: calc(175px - 15px);
      }

      .owl-carousel .owl-nav button.owl-prev,
      .owl-carousel .owl-nav button.owl-next {
        width: 25px;
        height: 25px;
      }

      .owl-carousel .owl-nav button.owl-prev img,
      .owl-carousel .owl-nav button.owl-next img {
        width: 7px;
      }
    }
  }
}
