@import "assets/scss/vars.scss";

.Wrapper {
  padding-bottom: 25px;
  margin-bottom: 30px;
  // margin-top: 15px;
}

.CreditCard {
  width: 100%;
  padding: 30px 30px 45px 30px;
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 7px 4px 10px 0 rgba(0, 0, 0, 0.04);
  position: relative;
  background-color: #fff;
}

.CreditCard:hover {
  box-shadow: 0px 0px 88px 10px rgba(0, 14, 92, 0.15);
}

.CreditCard .Tit {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.CreditCard .Tit img {
  width: 50px;
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
}

.Description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.CreditCard .Button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.CreditCard .Button.Disabled {
  cursor: no-drop;
}

// Applied Service
.disabledServices {
  cursor: no-drop !important;
}

@media (max-width: 768px) {
  .CreditCard {
    padding: 30px 30px 45px 30px;
  }
}
