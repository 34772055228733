@import "assets/scss/vars.scss";

.TableTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.TableTitle .Tit {
  #{$ltr} & {
    margin-right: 30px;
  }
  #{$rtl} & {
    margin-left: 30px;
  }
  text-transform: uppercase;
}
.TitleList {
  display: flex;
}
.TitleList span {
  #{$ltr} & {
    margin-right: 47px;
  }
  #{$rtl} & {
    margin-left: 47px;
  }
  cursor: pointer;
  white-space: nowrap;
}
.TableTitle span:last-child {
  #{$ltr} & {
    margin-right: 0;
  }
  #{$rtl} & {
    margin-left: 0;
  }
}

.errorMsg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DescFont {
  color: #e22523 !important;
}

.backLink {
  text-transform: uppercase;
  text-decoration: underline !important;
}

@media only screen and (max-width: 768px) {
  .TableTitle {
    display: block;
  }
  .TitleList {
    overflow-x: auto;
  }
  .TableTitle .Tit {
    margin-bottom: 30px;
    #{$ltr} & {
      margin-right: 0;
    }
    #{$rtl} & {
      margin-left: 0;
    }
  }
}
