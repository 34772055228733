@import "assets/scss/vars.scss";

.Head {
  background-color: #030961;
  padding: 30px 0 40px;
}
.Head {
  position: relative;
  background-color: #030961;
  padding-top: 30px;
}
.Head::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 140px;
  background-color: #fcfcfc;
  bottom: 0;
  left: 0;
}
.Head :global(.row) {
  align-items: flex-end;
}
.Head .row .col-12:last-child {
  padding-bottom: 25px;
}
.Head .scrollDown {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: block;
  color: #ffffff;
  margin-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  .Head {
    padding-bottom: 30px;
  }
}
