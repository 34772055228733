@import "assets/scss/vars.scss";

.SearchDrop {
  position: relative;
  z-index: 9;
  width: 100%;
  top: 100%;
  left: 0;
}

.SearchInput {
  padding: 0;
  border-top: 1px solid transparent;
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
  border-width: 0;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}

.SearchInput.Open {
  border-width: 1px;
  height: 188px;
  padding: 75px 0;
  overflow: visible;
}

.SearchInput .SrchInput {
  font-size: 72px;
  font-family: "raleway-b";
  color: rgba(207, 189, 168, 0.2);
  position: relative;
  left: -50%;
  opacity: 0;
  transition: 0.5s ease;
  border: 0;
  box-shadow: none;
}

.SearchInput.Open .SrchInput {
  left: 0;
  opacity: 1;
  transition-delay: 0.3s;
  width: 100%;
  border: 0;
  outline: none;
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  font-family: LatoBl;
  font-size: 30px;
}

.SearchInput.Open .SrchInput:focus ~ .Hints {
  visibility: visible;
  opacity: 1;
}

.SearchInput .SrchInput::placeholder {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  font-family: LatoBl;
  font-size: 30px;
}

.SearchInput {
  :global {
    .container {
      position: relative;
    }
  }
}

.SearchInput .Hints {
  position: absolute;
  top: 70%;
  background-color: #fff;
  width: 445px;
  max-width: 100%;
  border: 1px solid #000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.SearchInput .Hints {
  padding: 0 20px;
}

.SearchInput .Hints a {
  color: #000;
  padding: 30px 0;
  display: block;
  border-bottom: 1px solid #000;
}

.SearchInput .Hints li:last-child a {
  border: 0;
}

@media (max-width: 768px) {
  .SearchDrop {
    overflow: hidden !important;
  }
}
