@import "assets/scss/vars.scss";

.Wrapper {
  padding-bottom: 25px;
  margin-bottom: 30px;
  // margin-top: 15px;
}

.Offers {
  width: 100%;
  padding: 30px 30px 50px 30px;
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.04);
  position: relative;
}

.Offers:hover {
  box-shadow: 0px 0px 100px -15px rgba(0, 14, 92, 0.15);
}

.OffersContent {
  display: flex;
  align-items: center;
}

.OffersContent .Img {
  width: 101px;
  height: 101px;
  flex-shrink: 0;
  border: 1px solid rgba(112, 112, 112, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  #{$ltr} & {
    margin-right: 20px;
  }
  #{$rtl} & {
    margin-left: 20px;
  }
}

.OffersContent .Img img {
  width: 61px;
}

.Offers .Button {
  position: absolute;
  bottom: 0;
  left: 97px;
  transform: translateY(50%);
}

.OffersContent .Date {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}

.OffersContent .Desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}
